import './style.css';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useState } from 'react';

export default function DateRangeSelector(props) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);

  const onDateChange = (newDate, key) => {
    const date = dayjs(newDate).format('MM/DD/YYYY');

    if (date !== 'Invalid Date') {
      if (key === 'startDate') {
        setStartDate(date);
        props.updateDateRange(props.id, date, endDate);
      } else {
        setEndDate(date);
        props.updateDateRange(props.id, startDate, date);
      }
    }
  };

  return (
    <div className="date-range">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={1} className="options">
          <DatePicker
            open={startDatePickerOpen}
            onClose={() => setStartDatePickerOpen(false)}
            value={startDate}
            onChange={(date) => onDateChange(date, 'startDate')}
            renderInput={(props) => (
              <TextField {...props} InputProps={{}} size="small" onClick={() => setStartDatePickerOpen(true)} />
            )}
          />
          <p>&nbsp;-&nbsp;</p>
          <DatePicker
            open={endDatePickerOpen}
            onClose={() => setEndDatePickerOpen(false)}
            value={endDate}
            onChange={(date) => onDateChange(date, 'endDate')}
            renderInput={(props) => (
              <TextField {...props} InputProps={{}} size="small" onClick={() => setEndDatePickerOpen(true)} />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
}
