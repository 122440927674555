import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import { extractNameDobDc, getFormattedDate } from '../../utils/helpers';
import Notification from '../Notification/component';
import Search from './Search/component';

export default function PatientSearch() {
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [initialRender, setInitialRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [searchValues, setSearchValues] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    dischargeDate: dayjs().subtract(1, 'day').format('MM/DD/YYYY'),
  });
  const [ingestionData, setIngestionData] = useState({});
  const [showDateError, setShowDateError] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (location.state && location.state.uploadedPatient) {
      const newPatient = location.state.uploadedPatient;

      setSearchValues({
        firstName: newPatient.firstName,
        lastName: newPatient.lastName,
        dob: newPatient.dob,
      });
    }

    if (location.search) {
      const parsed = querystring.parse(location.search.slice(1));
      const patient = extractNameDobDc(parsed);
      const { firstName, lastName, dob, dc } = patient;
      const { ingestionId, patientId, meditechId, phone } = parsed;
      const dischargeDate = dc ? getFormattedDate(new Date(dc)) : undefined;

      setSearchValues({
        ...patient,
        dischargeDate,
      });
      searchPatients(firstName, lastName, dob, dischargeDate);
      setIngestionData({ ingestionId, patientId, dob, meditechId, phone, dischargeDate });
    }
  }, []);

  const searchPatients = async (firstName, lastName, dob, dc) => {
    if (dob.length < 10) {
      setShowDateError(true);

      return;
    }

    setShowDateError(false);

    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const patientsToSet = await BaymaxApi.searchPatients(firstName, lastName, dob, dc, token);

      setInitialRender(false);
      setSearchValues({ firstName, lastName, dob, dischargeDate: dc });
      setPatients(patientsToSet);
      setLoading(false);
    } catch (err) {
      setErrors(err);
      setLoading(false);
    }
  };

  const handleDischargeDate = (date) => {
    if (date) {
      const { firstName, lastName, dob } = searchValues;
      const dischargeDate = date.format('MM/DD/YYYY');

      if (date.format() !== 'Invalid Date') {
        setSearchValues({
          ...searchValues,
          dischargeDate,
        });
        searchPatients(firstName, lastName, dob, dischargeDate);
      }
    }
  };

  const clearDate = () => {
    setSearchValues({
      ...searchValues,
      dischargeDate: '',
    });
  };

  const tcmColor = (text) => {
    if (text === 'Eligible') return 'green-text';
    if (text === 'Not Eligible') return 'red-text';
    if (text.startsWith('Review:')) return 'orange-text';
    return '';
  };

  return (
    <Container className="patient-search">
      <Notification errors={errors} />
      <div className="header">
        <h1>Patient Search</h1>
      </div>
      <div className="discharge-date">
        <DateInput
          value={searchValues.dischargeDate}
          handleChange={handleDischargeDate}
          clearDate={clearDate}
          hideTime
        />
      </div>
      <Search
        loading={loading}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        searchPatients={searchPatients}
        showDateError={showDateError}
      />
      <TableContainer className="patient-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Patient Name</TableCell>
              <TableCell align="center">Date of Birth</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center">Facility</TableCell>
              <TableCell align="center">Last TCM Billed</TableCell>
              <TableCell align="center">Last TCM Visit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? null
              : patients.map((patient) => (
                  <TableRow key={patient.athenaId}>
                    <TableCell align="center">
                      <Link
                        to={`/patients/${patient.id || 'new'}`}
                        state={{
                          id: patient.id,
                          patient,
                          athenaId: patient.athenaId,
                          ingestionData,
                          facilityId: patient.facility.id,
                        }}
                      >
                        {patient.lastName}, {patient.firstName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{patient.dateOfBirth}</TableCell>
                    <TableCell align="center">{patient.phone}</TableCell>
                    <TableCell align="center">{patient.facility.name}</TableCell>
                    <TableCell align="center">
                      <div className={tcmColor(patient.lastTcmBilled.text)}>{patient.lastTcmBilled.text}</div>
                      <br />
                      <div className="normal-text">{patient.lastTcmBilled.description ?? ''}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={tcmColor(patient.lastTcmVisit.text)}>{patient.lastTcmVisit.text}</div>
                      <br />
                      <div className="normal-text">{patient.lastTcmVisit.description ?? ''}</div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {loading ? (
          <Loader />
        ) : (
          <div className="footnote">
            {initialRender ? (
              <p>Try searching for a patient...</p>
            ) : patients.length ? (
              ''
            ) : (
              <p>Can not find your patient?</p>
            )}

            {location.state && location.state.uploadId ? (
              <Button>
                <Link to="/patients/upload/processed" state={{ uploadId: location.state.uploadId }}>
                  Return to uploads
                </Link>
              </Button>
            ) : null}

            {patients.length ? null : (
              <Button variant="contained">
                <Link to="/patients/new" state={{ searchValues, ingestionData }}>
                  Add New Patient
                </Link>
              </Button>
            )}
          </div>
        )}
      </TableContainer>
    </Container>
  );
}
