import './style.scss';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectQuery, setQuery } from '../../state/systemSlice';

export default function SearchFilter(props) {
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);

  const handleChange = (event) => {
    dispatch(setQuery(event.target.value));
  };

  return (
    <div className="table-filter search-filter">
      <TextField
        placeholder="Search by Patient Name"
        value={query}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            props.fetchPatients();
          }
        }}
        sx={{
          fieldset: { borderColor: '#20ce99' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => props.fetchPatients()}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
