import './style.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectFilteredProviders, selectProviders, setFilteredProviders } from '../../state/systemSlice';

export default function ProviderFilter() {
  const dispatch = useDispatch();
  const selectedProviders = useSelector(selectFilteredProviders);
  const [anchorEl, setAnchorEl] = useState(null);
  const providers = useSelector(selectProviders);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const providerId = Number(event.target.attributes.provider.value);
    const provider = find(providers, ['id', providerId]);

    dispatch(setFilteredProviders(provider));
  };

  const isChecked = (provider) => {
    if (find(selectedProviders, ['id', provider.id])) {
      return true;
    }

    return false;
  };

  return (
    <div className="table-filter provider-filter">
      <Button variant="outlined" onClick={openMenu}>
        Providers
        {selectedProviders ? <p>{selectedProviders.length ? `(${selectedProviders.length})` : null}</p> : null}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <h3>Providers</h3>
        {providers.map((provider) => (
          <MenuItem key={provider.id} provider={provider.id} onClick={handleChange}>
            <Checkbox checked={isChecked(provider)} disabled />
            {provider.displayName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
