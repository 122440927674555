import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: {
    startDate: '',
    endDate: '',
  },
};

export const systemSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setSearchDate: (state, action) => {
      const { date, key } = action.payload;

      state.search[key] = date;
    },
    clearSearchDate: (state, action) => {
      const key = action.payload;

      state.search[key] = '';
    },
  },
});

export const { clearSearchDate, setSearchDate } = systemSlice.actions;

export default systemSlice.reducer;

export const selectSearchStartDate = (state) => state.invoice.search.startDate;
export const selectSearchEndDate = (state) => state.invoice.search.endDate;
