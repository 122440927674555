import dayjs from 'dayjs';

const reportData = {
  eligible: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  icCompleted: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  connected: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  documented: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  f2fWithinTwoDays: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  f2fCompleted: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  billed: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  phoneSuccessRate: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  conversionRate: {
    assigned: 0.0,
    unassigned: 0.0,
    total: 0,
  },
  notConverted: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  active: {
    assigned: 0,
    unassigned: 0,
    total: 0,
  },
  benchmark: {
    assigned: 0.0,
    unassigned: 0.0,
    total: 0,
  },
  changeFromBenchmark: {
    assigned: 0.0,
    unassigned: 0.0,
    total: 0,
  },
};

const currentMonth = dayjs().month();

export const REPORT_BY_PERIOD_INITIAL_STATE = {
  dateRangeOne: {
    startDate: dayjs()
      .month(currentMonth - 2)
      .startOf('month')
      .format('MM/DD/YYYY'),
    endDate: dayjs()
      .month(currentMonth - 2)
      .endOf('month')
      .format('MM/DD/YYYY'),
    data: reportData,
  },
  dateRangeTwo: {
    startDate: dayjs()
      .month(currentMonth - 1)
      .startOf('month')
      .format('MM/DD/YYYY'),
    endDate: dayjs()
      .month(currentMonth - 1)
      .endOf('month')
      .format('MM/DD/YYYY'),
    data: reportData,
  },
  dateRangeThree: {
    startDate: dayjs().month(currentMonth).startOf('month').format('MM/DD/YYYY'),
    endDate: dayjs().month(currentMonth).endOf('month').format('MM/DD/YYYY'),
    data: reportData,
  },
};

export const DATE_RANGES = Object.keys(REPORT_BY_PERIOD_INITIAL_STATE);

export const REPORT_BY_PERIOD_ROWS = [
  {
    id: 'eligible',
    name: 'Kouper Eligible',
    rowClasses: '',
    cellClasses: 'bold',
    isPercentage: false,
  },
  {
    id: 'icCompleted',
    name: 'IC Completed',
    rowClasses: '',
    cellClasses: 'bold',
    isPercentage: false,
  },
  {
    id: 'connected',
    name: 'IC Calls Connected',
    rowClasses: '',
    cellClasses: 'subcategory',
    isPercentage: false,
  },
  {
    id: 'documented',
    name: 'IC Async Documented',
    rowClasses: '',
    cellClasses: 'subcategory',
    isPercentage: false,
  },
  {
    id: 'f2fWithinTwoDays',
    name: 'F2F Within 2-Days',
    rowClasses: '',
    cellClasses: 'subcategory',
    isPercentage: false,
  },
  {
    id: 'f2fCompleted',
    name: 'F2F Completed',
    rowClasses: '',
    cellClasses: 'bold',
    isPercentage: false,
  },
  {
    id: 'billed',
    name: 'Billed',
    rowClasses: '',
    cellClasses: 'bold',
    isPercentage: false,
  },
  {
    id: 'phoneSuccessRate',
    name: 'Phone Success Rate',
    rowClasses: 'row-line',
    cellClasses: 'italic',
    isPercentage: true,
  },
  {
    id: 'conversionRate',
    name: 'IC to Bill Conversion',
    rowClasses: '',
    cellClasses: 'bold',
    isPercentage: true,
  },
  {
    id: 'notConverted',
    name: 'Not able to convert',
    rowClasses: '',
    cellClasses: '',
    isPercentage: false,
  },
  {
    id: 'active',
    name: 'Active',
    rowClasses: '',
    cellClasses: '',
    isPercentage: false,
  },
  {
    id: 'benchmark',
    name: 'Benchmark',
    rowClasses: '',
    cellClasses: '',
    isPercentage: false,
  },
  {
    id: 'changeFromBenchmark',
    name: '% Change from Benchmark',
    rowClasses: '',
    cellClasses: '',
    isPercentage: true,
  },
];
