import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';

const appDomain = process.env.COHERENCE_ENVIRONMENT_DOMAIN || process.env.REACT_APP_HOST;

export default function Login() {
  const APP_HOST = appDomain.startsWith('http') ? appDomain : `https://${appDomain}`;
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  });

  return (
    <div className="login-container">
      {isLoading ? null : (
        <div className="box">
          <img className="athena-logo" src={logo} alt="Kouper Health" />
          <div
            className="athenahealthsignin"
            onClick={() =>
              loginWithRedirect({
                redirectUri: `${APP_HOST}/dashboard`,
                screen_hist: 'login',
              })
            }
          >
            Log In
          </div>
        </div>
      )}
    </div>
  );
}
