import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPatientStatus, setPatientStatus } from '../../state/systemSlice';
import { PATIENT_STATUS } from '../../utils/constants/patientTable';

export default function StatusFilter() {
  const dispatch = useDispatch();
  const patientStatus = useSelector(selectPatientStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const value = event.target.attributes?.value?.nodeValue;

    if (value) {
      dispatch(setPatientStatus(value));
    }

    setAnchorEl(null);
  };

  const getStatusLabel = () => {
    return find(PATIENT_STATUS, ['value', patientStatus]).label;
  };

  return (
    <div className="status-filter">
      <Button variant="outlined" onClick={handleClick}>
        Status: <p>{getStatusLabel()}</p>
      </Button>
      <Menu className="status-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {PATIENT_STATUS.map((status) => (
          <MenuItem key={status.value} value={status.value} onClick={handleClose}>
            {status.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
