import '../styles/components/RadioSelect.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';

import { str2bool } from '../utils/helpers';

export default function RadioSelect(props) {
  const { convertToBool, direction, handleChange, name, options, validationMessage, value } = props;

  const classNames = `radioSelect ${direction}`;

  const setValue = (event) => {
    let value = event.target.value || event.target.innerText;

    if (convertToBool) {
      value = str2bool(value);
    }

    handleChange(name, value);
  };

  return (
    <RadioGroup className={classNames} value={value}>
      {options.map((option) => (
        <FormControlLabel
          control={<Radio onClick={setValue} />}
          key={`${name}-${option}`}
          label={option}
          value={option}
        />
      ))}

      {validationMessage ? <span className="required">{validationMessage}</span> : null}
    </RadioGroup>
  );
}
