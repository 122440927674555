import { createSelector } from '@reduxjs/toolkit';

import { selectIcForms } from '../icFormSlice';

export const getIcFormByPatientId = createSelector(
  selectIcForms,
  (_, patientId) => patientId,
  (icForms, patientId) => {
    if (!icForms[patientId]) {
      return null;
    }
    const patientForm = icForms[patientId];
    return patientForm;
  }
);
