import './style.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';

export default function InvoiceTable(props) {
  const [showVoidedInvoices, setShowVoidedInvoices] = useState(false);

  const invoices = () => {
    if (showVoidedInvoices) {
      return props.invoices;
    }

    return props.invoices.filter((invoice) => invoice.status === 'approved');
  };

  return props.loading ? (
    <Loader />
  ) : (
    <div className="invoice-table">
      <FormGroup className="breakdown-toggle">
        <FormControlLabel
          control={<Switch checked={showVoidedInvoices} onChange={() => setShowVoidedInvoices(!showVoidedInvoices)} />}
          label="Show Voided"
          labelPlacement="start"
        />
      </FormGroup>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Period Start</TableCell>
              <TableCell>Period End</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices().map((row) => (
              <TableRow key={row.invoiceNumber}>
                <TableCell id={row.id} className={`invoice-number ${row.status}`}>
                  <Link to={`/invoicing/${row.id}`} state={{ invoiceId: row.id }}>
                    {row.invoiceNumber}
                  </Link>
                </TableCell>
                <TableCell>{dayjs(row.issueDate).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{dayjs(row.periodStart).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{dayjs(row.periodEnd).format('MM/DD/YYYY')}</TableCell>
                <TableCell>${row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
