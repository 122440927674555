import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';

import BaymaxApi from '../../BaymaxApi';

export default function SummaryReport() {
  const { getAccessTokenSilently } = useAuth0();
  const [summary, setSummary] = useState({});

  useEffect(() => {
    fetchSummary();
  }, []);

  const fetchSummary = async () => {
    const token = await getAccessTokenSilently();
    const summary = await BaymaxApi.getSummaryReport(token);

    setSummary(summary);
  };

  return (
    <div className="summary">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>TCM Billed</TableCell>
              <TableCell>{summary.billed}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TCM Active</TableCell>
              <TableCell>{summary.active}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Unable to Reach or Confirm</TableCell>
              <TableCell>{summary.unknown}</TableCell>
            </TableRow>
            <TableRow className="row-line">
              <TableCell className="bold">Total Count</TableCell>
              <TableCell className="bold">{summary.total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
