import Alert from '@mui/material/Alert';
import React, { memo, useEffect, useState } from 'react';

function Notification(props) {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <div className="notification">
      {props.showSuccess ? (
        <Alert
          severity="success"
          onClose={() => {
            props.setShowSuccess(false);
          }}
        >
          Saved successfully!
        </Alert>
      ) : null}
      {errors.length ? (
        <Alert
          severity="error"
          onClose={() => {
            setErrors([]);
            props.setErrors([]);
          }}
        >
          {errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      ) : null}
    </div>
  );
}

export default memo(Notification);
