import axios from 'axios';

import MAX_QUERY_LIMIT from './utils/constants/system';

const BASE_URL = process.env.REACT_APP_API_HOST || '';

const createArrayQuery = (key, arr) => {
  if (arr.length === 1) {
    return `${key}[]=${arr[0]}&`;
  }

  if (arr.length > 1) {
    let query = '';

    arr.forEach((code) => (query = query.concat(`${key}=${code}&`)));

    return query;
  }

  return '';
};

export default class BaymaxApi {
  static practice = {};

  static buildUrl(endpoint, practiceNotRequired) {
    let url = '';

    if (practiceNotRequired) {
      url = `${BASE_URL}/api/v1/${endpoint}`;
    } else {
      url = `${BASE_URL}/api/v1/practice/${this.practice.id}/${endpoint}`;
    }

    return url;
  }

  static async request(endpoint, paramsOrData = {}, verb = 'get', token = '', practiceNotRequired = false) {
    try {
      return (
        await axios({
          method: verb,
          url: this.buildUrl(endpoint, practiceNotRequired),
          [verb === 'get' ? 'params' : 'data']: paramsOrData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;
    } catch (err) {
      if (Array.isArray(err.response.data.message)) {
        throw err.response.data.message;
      } else {
        throw [err.response.data.message];
      }
    }
  }

  // GET /practice
  static async getPractices(token) {
    return this.request('practice', {}, 'GET', token, true);
  }

  // GET /patients
  static async getPatients(filters, token) {
    const { query, patientStatus, providers, billingCodes, startDate, endDate } = filters;
    const pcp = providers.map((provider) => provider.id);
    const pcpQuery = createArrayQuery('pcp', pcp);
    const billingCodeQuery = createArrayQuery('billingCode', billingCodes);
    const queryString = `patients?${pcpQuery}${billingCodeQuery}patientStatus=${patientStatus}&startDate=${startDate}&endDate=${endDate}&query=${query}`;

    if (queryString.length > MAX_QUERY_LIMIT) {
      throw new Error('Too many filters applied.');
    }

    return this.request(queryString, {}, 'GET', token);
  }

  // POST /patients
  static async createPatient(payload, token) {
    return this.request('patients', payload, 'POST', token);
  }

  // GET /patients/:id
  static async getPatient(id, token) {
    return this.request(`patients/${id}`, {}, 'GET', token);
  }

  // PUT /patients/:id
  static async updatePatient(id, payload, token) {
    return this.request(`patients/${id}`, payload, 'PUT', token);
  }

  // GET /patients/search
  static async searchPatients(firstName, lastName, dob, dc, token) {
    let path = `patients/search?firstname=${firstName}&lastname=${lastName}&dob=${dob}`;

    if (dc) {
      path = `${path}&dc=${dc}`;
    }
    return this.request(path, {}, 'GET', token);
  }

  // GET /providers
  static async getProviders(token) {
    return this.request('providers?active=true', {}, 'GET', token);
  }

  // GET /appointments
  static async getAppointmentsByProvider(id, pid, token) {
    return this.request(`providers/${id}/slots/${pid}`, {}, 'GET', token);
  }

  // GET /providers/:id/reassignables
  static async getProviderReassignables(id, token) {
    return this.request(`providers/${id}/reassignables`, {}, 'GET', token);
  }

  // POST /appointments
  static async bookAppointment(payload, token) {
    return this.request('appointments', payload, 'POST', token);
  }

  // PUT /appointments
  static async updateAppointment(id, payload, token) {
    return this.request(`appointments/${id}`, payload, 'PUT', token);
  }

  // GET patients/:id/icform
  static async getIcForm(id, token) {
    return this.request(`patients/${id}/icform`, {}, 'GET', token);
  }

  // PUT patients/:id/icform
  static async saveIcForm(id, payload, token) {
    return this.request(`patients/${id}/icform`, payload, 'PUT', token);
  }

  // POST patients/:id/icform
  static async submitIcForm(id, payload, token) {
    return this.request(`patients/${id}/icform`, payload, 'POST', token);
  }

  // GET /patient/:id/audit-history
  static async getAuditHistory(id, type, token) {
    return this.request(`patients/${id}/audit-history?type=${type}`, {}, 'GET', token);
  }

  // GET cases/:id
  static async getPatientCase(caseId, token) {
    return this.request(`cases/${caseId}`, {}, 'GET', token);
  }

  // PATCH cases/:id
  static async updatePatientCase(caseId, payload, token) {
    return this.request(`cases/${caseId}`, payload, 'PATCH', token);
  }

  // POST cases (create case)
  static async createPatientCase(patientId, token) {
    return this.request(`cases`, { patientId }, 'POST', token);
  }

  // POST cases (submit case)
  static async submitPatientCase(caseId, payload, token) {
    return this.request(`cases/${caseId}`, payload, 'POST', token);
  }

  // GET cases/:caseId/actions
  static async getCaseActionNotes(caseId, caseType, token) {
    return this.request(`cases/${caseId}/actions?type=${caseType}`, {}, 'GET', token);
  }

  // POST cases/:id/caseaction
  static async submitCaseAction(caseId, payload, token) {
    return this.request(`cases/${caseId}/action`, payload, 'POST', token);
  }

  // GET cases/updates
  static async reconcile(token) {
    return this.request(`cases/updates`, {}, 'GET', token);
  }

  // GET patients/:id/appointments
  static async getPatientAppointments(id, token) {
    return this.request(`patients/${id}/appointments`, {}, 'GET', token);
  }

  // POST ingestions
  static async uploadPatients(payload, token) {
    return this.request(`ingestions`, payload, 'POST', token);
  }

  // GET ingestion
  static async getUploadIds(token) {
    return this.request(`ingestions`, {}, 'GET', token);
  }

  // GET ingestions/:id
  static async getUpload(id, token) {
    return this.request(`ingestions/${id}`, {}, 'GET', token);
  }

  // PUT ingestions
  static async approvePatients(payload, token) {
    return this.request(`ingestions`, payload, 'PUT', token);
  }

  // PATCH ingestions/:ingestionId
  static async updateIngestionStatus(ingestionId, payload, token) {
    return this.request(`ingestions/${ingestionId}`, payload, 'PATCH', token);
  }

  // GET dashboard/summary
  static async getSummaryReport(token) {
    return this.request('dashboard/summary', {}, 'GET', token);
  }

  // GET dashboard/ic-metrics
  static async getIcMetrics(token) {
    return this.request('dashboard/ic-metrics', {}, 'GET', token);
  }

  // GET dashboard/ic-metrics/period
  static async getIcMetricsByPeriod(startDate, endDate, token) {
    return this.request(`dashboard/ic-metrics/detail?startDate=${startDate}&endDate=${endDate}`, {}, 'GET', token);
  }

  // GET dashboard/ic-metrics/bookings
  static async getIcMetricsBookings(token) {
    return this.request(`dashboard/ic-metrics/bookings`, {}, 'GET', token);
  }

  // GET dashboard/invoice
  static async getInvoices(token) {
    return this.request(`invoices`, {}, 'GET', token);
  }

  // GET dashboard/invoice/draft
  static async generateInvoice(payload, token) {
    const { startDate, endDate } = payload;

    return this.request(`invoices/draft?startDate=${startDate}&endDate=${endDate}`, {}, 'GET', token);
  }

  // POST dashboard/invoice
  static async approveInvoice(payload, token) {
    return this.request(`invoices`, payload, 'POST', token);
  }

  // GET dashboard/invoice/:id
  static async getInvoice(invoiceId, token) {
    return this.request(`invoices/${invoiceId}`, {}, 'GET', token);
  }

  // POST dashboard/invoice/:id
  static async voidInvoice(invoiceId, token) {
    return this.request(`invoices/${invoiceId}`, {}, 'POST', token);
  }

  // GET dashboard/invoice/search
  static async searchInvoice(startDate, endDate, token) {
    return this.request(`invoices/search?startDate=${startDate}&endDate=${endDate}`, {}, 'GET', token);
  }

  // GET analytics
  static async getAnalytics(chartName, token) {
    return this.request(`analytics?chart=${chartName}`, {}, 'GET', token);
  }
}
