import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Label, LabelList, XAxis, YAxis } from 'recharts';

import BaymaxApi from '../../BaymaxApi';

export default function Visualization() {
  const { getAccessTokenSilently } = useAuth0();
  const [daysUntilFollowup, setDaysUntilFollowup] = useState([]);

  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    const token = await getAccessTokenSilently();
    const data = await BaymaxApi.getAnalytics('daysUntilFollowup', token);

    setDaysUntilFollowup(data);
  };

  const barLabelFormatter = (value) => {
    if (value < 1) {
      return null;
    }

    return value;
  };

  return (
    <Container className="visualization">
      <div className="chart">
        <h2>Days Until Followup</h2>
        <BarChart width={500} height={300} data={daysUntilFollowup}>
          <XAxis dataKey="day" tickLine={false} tickSize={2}>
            <Label value="Days from Discharge" offset={0} position="insideBottom" />
          </XAxis>
          <YAxis tickCount={6} tickLine={false} tickSize={2} domain={[0, 'dataMax']}>
            <Label value="# of Patients" offset={130} position="insideBottom" angle={-90} />
          </YAxis>
          <Bar dataKey="amount" fill="#20CE99">
            <LabelList dataKey="amount" position="center" fill="white" formatter={barLabelFormatter} />
          </Bar>
        </BarChart>
      </div>
    </Container>
  );
}
