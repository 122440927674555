import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  icForms: {},
};

export const icFormSlice = createSlice({
  name: 'icForm',
  initialState,
  reducers: {
    setIcForm: (state, action) => {
      state.icForms[action.payload.patientId] = action.payload;
    },
    resetSystemState: (state) => {
      state = initialState;
    },
  },
});

export const { resetSystemState, setIcForm } = icFormSlice.actions;

export default icFormSlice.reducer;

export const selectIcForms = (state) => state.icForm.icForms;
