export const YES_NO = ['Yes', 'No'];

export const YES_NO_NOT_APPLICABLE = ['Yes', 'No', 'N/A'];

export const DISCHARGE_FACILITY_TYPES = [
  'Inpatient acute care hospital',
  'Inpatient psychiatric hospital',
  'Long-term care hospital',
  'Skilled nursing facility',
  'Inpatient rehabilitation facility',
  'Hospital outpatient observation or partial hospitalization',
  'Partial hospitalization at a community mental health center',
];

export const DISCHARGE_DESTINATION_TYPES = ['Home', 'Domiciliary', 'Nursing facility', 'Assisted living facility'];

export const POST_DISCHARGE_QUESTIONS = {
  adjustmentToHome: '',
  nutrition: '',
  mobility: '',
  dischargeInstructionEducation: '',
  prescription: '',
  medicationCompliance: '',
  serviceNeeds: {
    none: '',
    homeHealth: '',
    physicalTherapy: '',
    other: '',
  },
  version: '',
};
