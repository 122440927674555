import '../styles/components/PracticeSelect.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BaymaxApi from '../BaymaxApi';
import {
  selectCurrentPractice,
  selectPractices,
  setCurrentPractice,
  setLoading,
  setPractices,
  setShowPracticeSelect,
} from '../state/systemSlice';
import { fetchProviders } from '../utils/helpers-api';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function PracticeSelect(props) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPractice = useSelector(selectCurrentPractice);
  const practices = useSelector(selectPractices);
  const [open, setOpen] = useState(true);
  const [selectedPractice, setSelectedPractice] = useState({});

  useEffect(() => {
    if (isEmpty(practices)) {
      getPractices();
    }
  }, []);

  const getPractices = async () => {
    try {
      const token = await getAccessTokenSilently();
      const practices = await BaymaxApi.getPractices(token);

      dispatch(setPractices(practices));
    } catch (error) {
      console.log(error);
    }
  };

  const selectPractice = (event) => {
    const selectedPractice = practices.find((practice) => practice.id === event.target.value);

    setSelectedPractice(selectedPractice);
  };

  const submit = async () => {
    const token = await getAccessTokenSilently();

    BaymaxApi.practice = selectedPractice;
    localStorage.setItem('kouperPractice', JSON.stringify(selectedPractice));
    await fetchProviders(true, token);
    setOpen(false);
    dispatch(setCurrentPractice(selectedPractice));
    dispatch(setShowPracticeSelect(false));
    dispatch(setLoading(false));
    props.practiceRef.current = selectedPractice;

    if (location.pathname !== '/invoicing') {
      navigate('/');
    }
  };

  const cancel = () => {
    dispatch(setShowPracticeSelect(false));
    dispatch(setLoading(false));
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={modalStyle} className="practice-select">
          <div className="select">
            Select Practice:
            <FormControl>
              <Select value={selectedPractice.id || ''} onChange={selectPractice}>
                {practices.map((practice) => (
                  <MenuItem key={practice.id} value={practice.id}>
                    {practice.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="submit">
            <Button variant="contained" onClick={submit} disabled={isEmpty(selectedPractice)}>
              Continue
            </Button>
            {isEmpty(currentPractice) ? null : (
              <Button variant="contained" onClick={cancel}>
                Cancel
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
