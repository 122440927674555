import '../styles/components/Loader.scss';

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function Loader() {
  return (
    <div className="loader" title="Loading">
      <CircularProgress />
    </div>
  );
}
