import dayjs from 'dayjs';

export const str2bool = (value) => {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'yes') return true;
    if (value.toLowerCase() === 'no') return false;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return null;
};

export const bool2str = (value) => {
  if (typeof value === 'boolean') {
    if (value === true) return 'Yes';
    if (value === false) return 'No';
  }

  if (typeof value === 'string') {
    return value;
  }

  return null;
};

export function titleCase(text) {
  const titleCaseTerm = (t) => t.charAt(0).toUpperCase() + t.slice(1);
  const titleCaseSentence = (s) =>
    s
      .split(/(\s+)/)
      .filter((w) => w.trim().length > 0)
      .map((w) => titleCaseTerm(w))
      .join(' ');

  return text
    ? text
        .toLowerCase()
        .split(',')
        .filter((s) => s.trim().length > 0)
        .map((s) => titleCaseSentence(s))
        .join(',')
    : text;
}

export const parsePersonName = (text) => {
  const lastAndFirstNames = titleCase(text).split(',');
  if (lastAndFirstNames.length < 2) {
    return { lastName: text, firstName: undefined };
  }
  const lastName = lastAndFirstNames[0]
    .split(/(\s+)/)
    .filter((w) => w.trim().length > 0)
    .join(' ');
  const firstName = lastAndFirstNames[1]
    .split(/(\s+)/)
    .filter((w) => w.trim().length > 0)
    .shift();
  return { lastName, firstName };
};

export const extractNameDobDc = (row) => {
  const { name, dob, dc } = row;
  const { lastName, firstName } = parsePersonName(name);
  return { dob, firstName, lastName, dc };
};

export function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}

export const convertToUtc = (date) => {
  return date.utc().format();
};

export const getCurrentTime = (date) => {
  const current = dayjs();

  return date.hour(current.hour()).minute(current.minute());
};

export const phoneFormat = (input) => {
  if (input) {
    input = input.replace(/\D/g, '');
    const size = input.length;
    if (size > 0) {
      input = `(${input}`;
    }
    if (size > 3) {
      input = `${input.slice(0, 4)}) ${input.slice(4, 11)}`;
    }
    if (size > 6) {
      input = `${input.slice(0, 9)}-${input.slice(9)}`;
    }
    return input;
  }
  return '';
};

export const disableCurrentMonth = (date) => {
  const today = dayjs();
  const currentEndOfMonth = dayjs(date).endOf('month');

  if (today.isBefore(currentEndOfMonth.format('MM/DD/YYYY'))) {
    return true;
  }

  return false;
};

export const disablePastYears = (date) => {
  if (date.isBefore('2022', 'year')) {
    return true;
  }

  return false;
};

export const formatDate = (value, timezone) => {
  if (value) {
    if (value.length < 11) {
      return value;
    }

    return dayjs(value).tz(timezone).format('MM/DD/YYYY hh:mm a');
  }

  return '';
};
