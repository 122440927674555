import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import { clearSearchDate, selectSearchEndDate, selectSearchStartDate, setSearchDate } from '../../state/invoiceSlice';
import InvoiceDetail from './InvoiceDetail';

export default function InvoiceSearch() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const startDate = useSelector(selectSearchStartDate);
  const endDate = useSelector(selectSearchEndDate);
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});

  useEffect(() => {
    if (startDate && endDate) {
      searchInvoice();
    }
  }, []);

  const searchInvoice = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const invoiceRows = await BaymaxApi.searchInvoice(startDate, endDate, token);

      setInvoice({ details: invoiceRows });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = (value, name) => {
    if (value && value.format() !== 'Invalid Date') {
      dispatch(setSearchDate({ date: value.format('MM/DD/YYYY'), key: name }));
    } else {
      dispatch(setSearchDate({ date: '', key: name }));
    }
  };

  const clearDate = (name) => {
    dispatch(clearSearchDate(name));
  };

  const isSearchDisabled = () => {
    return isEmpty(startDate) || isEmpty(endDate);
  };

  return (
    <Container className="invoicing preview">
      <h2>Invoice Search</h2>

      <div className="invoice-options">
        <DateInput
          name="startDate"
          label="Start Date"
          value={startDate}
          handleChange={handleDateChange}
          clearDate={clearDate}
          hideTime
        />
        <DateInput
          name="endDate"
          label="End Date"
          value={endDate}
          handleChange={handleDateChange}
          clearDate={clearDate}
          hideTime
        />
        <Button className="preview" variant="contained" onClick={searchInvoice} disabled={isSearchDisabled()}>
          Search
        </Button>
      </div>

      {isEmpty(invoice) ? null : loading ? (
        <Loader />
      ) : (
        <InvoiceDetail invoice={invoice} startDate={startDate} endDate={endDate} hideInfo hideSummary hideOptions />
      )}
    </Container>
  );
}
