import './style.scss';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';

export default function Search(props) {
  const { firstName, lastName, dob, dischargeDate } = props.searchValues;

  const dateFormat = (date) => {
    if (date[date.length - 1].match(/^\d+$/)) {
      // after entering two digit day
      if (date.length === 2 && date.match(/\d+/g)) {
        return (date += '/');
      }
      // after entering two digit months
      if ((date.length === 5 || date.length === 4) && date.match(/\d+\/\d\d/g)) {
        return (date += '/');
      }

      return date;
    }
    return date;
  };

  return (
    <div className="search">
      <TextField
        id="lastName"
        name="lastName"
        placeholder="Last Name"
        variant="outlined"
        value={lastName}
        onChange={(ev) => {
          props.setSearchValues({
            ...props.searchValues,
            lastName: ev.target.value,
          });
        }}
      />
      <TextField
        id="firstName"
        name="firstName"
        placeholder="First Name"
        variant="outlined"
        value={firstName}
        onChange={(ev) => {
          props.setSearchValues({
            ...props.searchValues,
            firstName: ev.target.value,
          });
        }}
      />
      <TextField
        error={props.showDateError}
        id="dateOfBirth"
        name="dateOfBirth"
        placeholder="Date of Birth"
        variant="outlined"
        helperText={props.showDateError ? 'Please enter in MM/DD/YYYY format' : ''}
        value={dob}
        onChange={(ev) => {
          if (ev.nativeEvent.inputType === 'deleteContentBackward') {
            props.setSearchValues({
              ...props.searchValues,
              dob: ev.target.value,
            });
          } else if (ev.target.value.length < 11) {
            props.setSearchValues({
              ...props.searchValues,
              dob: dateFormat(ev.target.value),
            });
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                disabled={props.loading}
                onClick={() => props.searchPatients(firstName, lastName, dob, dischargeDate)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
