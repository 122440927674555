import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaymaxApi from '../../BaymaxApi';
import Loader from '../../components/Loader';
import { setPatients } from '../../state/patientSlice';
import {
  resetFilters,
  selectAllFilters,
  selectEndDate,
  selectLoading,
  selectStartDate,
  setEndDate,
  setStartDate,
  showPracticeSelect,
} from '../../state/systemSlice';
import BillingCodeFilter from './BillingCodeFilter';
import ProviderFilter from './ProviderFilter';
import SearchFilter from './SearchFilter';
import StatusFilter from './StatusFilter';

export default function TableFilters() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const showPracticeSelectModal = useSelector(showPracticeSelect);
  const loading = useSelector(selectLoading);
  const filters = useSelector(selectAllFilters);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);

  useEffect(() => {
    if (!showPracticeSelectModal) {
      fetchPatients();
    }
  }, [filters.patientStatus, filters.providers, filters.billingCodes, filters.startDate, filters.endDate]);

  const fetchPatients = async () => {
    const token = await getAccessTokenSilently();
    const patients = await BaymaxApi.getPatients(filters, token);

    dispatch(setPatients(patients));
  };

  const refreshTable = async () => {
    await fetchPatients();
  };

  const resetTableFilters = () => {
    dispatch(resetFilters());
  };

  return (
    <div className="table-filters">
      <StatusFilter />
      <div className="date-filters">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => {
              dispatch(setStartDate(date.format('MM/DD/YYYY')));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => {
              dispatch(setEndDate(date.format('MM/DD/YYYY')));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <ProviderFilter />
      <BillingCodeFilter />
      <SearchFilter fetchPatients={fetchPatients} />
      <Button className="reset" variant="outlined" onClick={resetTableFilters}>
        Reset
      </Button>
      <div className="refresh">
        {loading ? (
          <Loader />
        ) : (
          <IconButton onClick={refreshTable}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
