import '../styles/components/CaseActionNotes.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { capitalize, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import BaymaxApi from '../BaymaxApi';
import Notification from '../pages/Notification/component';
import Loader from './Loader';

export default function CaseActionNotes(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [actionNotes, setActionNotes] = useState([{}]);
  const [caseDetails, setCaseDetails] = useState({});
  const [note, setNote] = useState('');
  const [assignee, setAssignee] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchActionNotes(props.caseId);
  }, []);

  const fetchActionNotes = async (caseId) => {
    const token = await getAccessTokenSilently();
    const actionNotes = await BaymaxApi.getCaseActionNotes(caseId, props.caseType, token);

    setActionNotes(actionNotes.actions);
    setCaseDetails(actionNotes.ehrCaseDetails);
  };

  const submit = async () => {
    try {
      setLoading(true);
      setNote('');
      setAssignee({});

      const token = await getAccessTokenSilently();
      const { caseId } = props;
      const payload = {
        assigneeId: assignee.id,
        caseType: props.caseType,
        note: !isEmpty(note) ? note : undefined,
      };

      await BaymaxApi.submitCaseAction(caseId, payload, token);
      await fetchActionNotes(caseId);
      setLoading(false);
    } catch (err) {
      setErrors(err);
    }
  };

  const handleReassignChange = (event) => {
    const selected = props.staff.find((staff) => event.target.value === staff.id);

    setAssignee(selected);
  };

  const isDisabled = () => {
    if (isEmpty(note) && isEmpty(assignee)) {
      return true;
    }

    return false;
  };

  return (
    <div className="case-action-notes section">
      <h3>
        Case Action Notes:
        <div className="status">
          {capitalize(caseDetails?.status)}
          {caseDetails?.urgent && <span className="urgent">Urgent</span>}
        </div>
      </h3>
      {actionNotes.length ? (
        <div className="action-notes">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Created by</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Assigned to</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionNotes.map((note, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={`action-notes-${index}`}>
                    <TableCell>{note.createdDateTime}</TableCell>
                    <TableCell>{note.createdBy}</TableCell>
                    <TableCell>{note.status}</TableCell>
                    <TableCell>{note.assignedTo}</TableCell>
                    <TableCell>{note.actionNote}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="row">
            Action Note:
            <TextField
              multiline
              rows={4}
              className="action-note"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>
          <div className="row">
            Reassign to:
            <FormControl>
              <Select
                className="assignee"
                value={props.staff.find((p) => p.id === assignee.id)?.id || ''}
                onChange={handleReassignChange}
              >
                {props.staff.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" onClick={submit} disabled={isDisabled()}>
              Submit
            </Button>
            {loading ? <Loader /> : null}
          </div>
          <div className="row error">
            <Notification errors={errors} setErrors={setErrors} />
          </div>
        </div>
      ) : (
        <p>TCM patient case does not have any action notes yet.</p>
      )}
    </div>
  );
}
