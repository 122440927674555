import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import { INVOICE_OPTIONS_INITIAL } from '../../utils/constants/invoice';
import { disableCurrentMonth, disablePastYears } from '../../utils/helpers';
import InvoiceTable from './InvoiceTable';

export default function Invoice() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState(INVOICE_OPTIONS_INITIAL);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    const token = await getAccessTokenSilently();
    const invoices = await BaymaxApi.getInvoices(token);

    setInvoices(invoices);
    setLoading(false);
  };

  const handleDateChange = (value) => {
    const lastDayOfMonth = dayjs(value).endOf('month').format('MM/DD/YYYY');

    setInvoiceOptions({
      ...invoiceOptions,
      startDate: lastDayOfMonth,
      endDate: lastDayOfMonth,
    });
  };

  const clearDate = () => {
    setInvoiceOptions({
      ...invoiceOptions,
      startDate: '',
    });
  };

  const isPreviewDisabled = () => {
    return isEmpty(invoiceOptions.startDate);
  };

  return (
    <Container className="invoicing">
      <h2>Invoicing</h2>

      {loading ? <Loader /> : <InvoiceTable invoices={invoices} />}

      <div className="invoice-options">
        <DateInput
          view="month year"
          label="Invoice Period"
          value={invoiceOptions.startDate}
          handleChange={handleDateChange}
          clearDate={clearDate}
          shouldDisableMonth={disableCurrentMonth}
          shouldDisableYear={disablePastYears}
          disableFuture
          hideTime
        />
        <Link to="/invoicing/preview" state={{ invoiceOptions }}>
          <Button className="preview" variant="contained" disabled={isPreviewDisabled()}>
            Preview
          </Button>
        </Link>
        <Link to="/invoicing/search">
          <Button className="search" variant="outlined">
            Search by Period
          </Button>
        </Link>
      </div>
    </Container>
  );
}
