import { createSlice, current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { findIndex, reject } from 'lodash';

const initialState = {
  filters: {
    query: '',
    patientStatus: 'ready_ic',
    providers: [],
    billingCodes: [],
    startDate: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
  },
  loading: true,
  showPracticeSelect: false,
  currentPractice: {},
  practices: [],
  providers: [],
  providersWithoutUnassigned: [],
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentPractice: (state, action) => {
      state.currentPractice = action.payload;
    },
    setShowPracticeSelect: (state, action) => {
      state.showPracticeSelect = action.payload;
    },
    setPractices: (state, action) => {
      state.practices = action.payload;
    },
    setQuery: (state, action) => {
      state.filters.query = action.payload;
    },
    setPatientStatus: (state, action) => {
      state.filters.patientStatus = action.payload;
    },
    setProviders: (state, action) => {
      state.providers = action.payload.providers;
      state.providersWithoutUnassigned = action.payload.providersWithoutUnassigned;
    },
    setFilteredProviders: (state, action) => {
      const selectedProvider = action.payload;
      const currentProviders = current(state).filters.providers;

      if (findIndex(currentProviders, ['id', selectedProvider.id]) > -1) {
        state.filters.providers = reject(currentProviders, { id: selectedProvider.id });
      } else {
        state.filters.providers.push(selectedProvider);
      }
    },
    setSelectedBillingCodes: (state, action) => {
      const billingCode = action.payload;
      const selectedBillingCodes = current(state).filters.billingCodes;

      if (selectedBillingCodes.includes(billingCode)) {
        state.filters.billingCodes = selectedBillingCodes.filter((code) => code !== billingCode);
      } else {
        state.filters.billingCodes.push(billingCode);
      }
    },
    resetSystemState: (state) => {
      state = initialState;
    },
    resetFilters: (state) => {
      state.filters = { ...initialState.filters, patientStatus: state.filters.patientStatus };
    },
    setStartDate: (state, action) => {
      state.filters.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.filters.endDate = action.payload;
    },
  },
});

export const {
  setPatientStatus,
  setPcp,
  setCurrentPractice,
  setPractices,
  setQuery,
  setProviders,
  setFilteredProviders,
  setSelectedBillingCodes,
  setShowPracticeSelect,
  setLoading,
  setStartDate,
  setEndDate,
  resetSystemState,
  resetFilters,
} = systemSlice.actions;

export default systemSlice.reducer;

export const selectLoading = (state) => state.system.loading;
export const showPracticeSelect = (state) => state.system.showPracticeSelect;
export const selectCurrentPractice = (state) => state.system.currentPractice;
export const selectPractices = (state) => state.system.practices;
export const selectProviders = (state) => state.system.providers;
export const selectProvidersWithoutUnassigned = (state) => state.system.providersWithoutUnassigned;
export const selectAllFilters = (state) => state.system.filters;
export const selectQuery = (state) => state.system.filters.query;
export const selectPatientStatus = (state) => state.system.filters.patientStatus;
export const selectFilteredProviders = (state) => state.system.filters.providers;
export const selectBillingCodes = (state) => state.system.filters.billingCodes;
export const selectStartDate = (state) => state.system.filters.startDate;
export const selectEndDate = (state) => state.system.filters.endDate;
