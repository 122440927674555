import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import { INVOICE_OPTIONS_INITIAL } from '../../utils/constants/invoice';
import { disableCurrentMonth, disablePastYears } from '../../utils/helpers';
import InvoiceDetail from './InvoiceDetail';

export default function InvoicePreview() {
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoiceOptions, setInvoiceOptions] = useState({});

  useEffect(() => {
    const invoiceId = location?.state?.invoiceId || location.pathname.split('/').pop();
    const invoiceOptions = location?.state?.invoiceOptions;

    if (invoiceId && isEmpty(invoiceOptions)) {
      fetchInvoice(invoiceId);
    } else {
      const options = invoiceOptions || INVOICE_OPTIONS_INITIAL;

      setInvoiceOptions(options);
      getPreview(options);
    }
  }, []);

  const fetchInvoice = async (invoiceId) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const invoice = await BaymaxApi.getInvoice(invoiceId, token);

      invoice.isDraft = false;
      setInvoice(invoice);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPreview = async (options) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const payload = options || invoiceOptions;
      const invoice = await BaymaxApi.generateInvoice(payload, token);

      invoice.isDraft = true;
      setInvoice(invoice);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = (value) => {
    const lastDayOfMonth = dayjs(value).endOf('month').format('MM/DD/YYYY');

    setInvoiceOptions({
      ...invoiceOptions,
      startDate: lastDayOfMonth,
      endDate: lastDayOfMonth,
    });
  };

  const clearDate = () => {
    setInvoiceOptions({
      ...invoiceOptions,
      startDate: '',
    });
  };

  const isPreviewDisabled = () => {
    return isEmpty(invoiceOptions.startDate);
  };

  return (
    <Container className="invoicing preview">
      <h2>Invoice Preview</h2>

      {invoice.isDraft ? (
        <div className="invoice-options">
          <DateInput
            view="month year"
            label="Invoice Period"
            value={invoiceOptions.startDate}
            handleChange={handleDateChange}
            clearDate={clearDate}
            shouldDisableMonth={disableCurrentMonth}
            shouldDisableYear={disablePastYears}
            disableFuture
            hideTime
          />
          <Button
            className="preview"
            variant="contained"
            onClick={() => {
              getPreview(invoiceOptions);
            }}
            disabled={isPreviewDisabled()}
          >
            Preview
          </Button>
        </div>
      ) : null}

      {isEmpty(invoice) ? null : loading ? (
        <Loader />
      ) : (
        <InvoiceDetail
          invoice={invoice}
          startDate={dayjs(invoiceOptions.startDate).startOf('month').format('MM/DD/YYYY')}
          endDate={invoiceOptions.endDate}
        />
      )}
    </Container>
  );
}
