import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';

import BaymaxApi from '../../BaymaxApi';

export default function BookingsReport() {
  const { getAccessTokenSilently } = useAuth0();
  const [bookings, setBookings] = useState({});

  useEffect(() => {
    fetchIcMetricsBookings();
  }, []);

  const fetchIcMetricsBookings = async () => {
    const token = await getAccessTokenSilently();
    const bookings = await BaymaxApi.getIcMetricsBookings(token);

    setBookings(bookings);
  };

  return (
    <div className="bookings">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Booked, Billed</TableCell>
              <TableCell>{bookings.bookedAndBilled}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Case Submitted - In Review, Billed</TableCell>
              <TableCell>{bookings.caseInReviewAndBilled}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Case Submitted - Not in Review, Billed</TableCell>
              <TableCell>{bookings.caseSubmittedAndBilled}</TableCell>
            </TableRow>
            <TableRow className="row-line">
              <TableCell className="bold">Total Supported Follow-Up</TableCell>
              <TableCell className="bold">{bookings.total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
