import './App.scss';

import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, ScrollRestoration, useNavigate } from 'react-router-dom';

import ProtectedRoute from './auth/protected-route';
import BaymaxApi from './BaymaxApi';
import PracticeSelect from './components/PracticeSelect';
import Dashboard from './pages/Dashboard/component';
import InteractiveForm from './pages/InteractiveForm/component';
import InvoicePreview from './pages/Invoicing/InvoicePreview';
import InvoiceSearch from './pages/Invoicing/InvoiceSearch';
import Invoicing from './pages/Invoicing/Invoicing';
import Login from './pages/Login/component';
import Navbar from './pages/Navbar/component';
import PatientCase from './pages/PatientCase/component';
import PatientProfile from './pages/PatientProfile/component';
import PatientSearch from './pages/PatientSearch/component';
import Reports from './pages/Reports/component';
import Upload from './pages/Upload/component';
import UploadProcessed from './pages/UploadProcessed/component';
import {
  selectLoading,
  setCurrentPractice,
  setLoading,
  setShowPracticeSelect,
  showPracticeSelect,
} from './state/systemSlice';
import { setUserName, setUserType } from './state/userSlice';
import { fetchProviders } from './utils/helpers-api';

const { REACT_APP_AUTH0_DOMAIN } = process.env;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
  const practice = useRef(null);
  const showPracticeSelectModal = useSelector(showPracticeSelect);
  const [filters, setFilters] = useState({
    status: 'all',
    startDate: dayjs().subtract(30, 'days'),
    endDate: dayjs(),
  });

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const userRole = user[`${REACT_APP_AUTH0_DOMAIN}`].roles[0];
      const practiceInStorage = JSON.parse(localStorage.getItem('kouperPractice'));

      dispatch(setUserName(user.name));
      dispatch(setCurrentPractice(practiceInStorage));
      practice.current = practiceInStorage;

      if (isEmpty(practiceInStorage)) {
        dispatch(setShowPracticeSelect(true));
      } else {
        BaymaxApi.practice = practiceInStorage;
        loadInitialData();
        dispatch(setLoading(false));
      }

      if (userRole) {
        dispatch(setUserType(userRole));
      }
    }

    document.addEventListener('visibilitychange', checkForPractice);

    return () => {
      document.removeEventListener('visibilitychange', () => {});
    };
  }, [user]);

  const loadInitialData = async () => {
    const token = await getAccessTokenSilently();

    await fetchProviders(false, token);
  };

  const checkForPractice = () => {
    const practiceInStorage = JSON.parse(localStorage.getItem('kouperPractice'));

    if (practice.current.id !== practiceInStorage.id) {
      dispatch(setShowPracticeSelect(true));
      dispatch(setCurrentPractice({}));
      navigate('/');
    }
  };

  return (
    <div className="App">
      <Navbar />
      {!isAuthenticated ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
        </Routes>
      ) : null}
      {loading ? null : (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/dashboard"
            element={<ProtectedRoute component={Dashboard} filters={filters} setFilters={setFilters} />}
          />
          <Route exact path="/invoicing" element={<ProtectedRoute component={Invoicing} />} />
          <Route exact path="/invoicing/preview" element={<ProtectedRoute component={InvoicePreview} />} />
          <Route exact path="/invoicing/search" element={<ProtectedRoute component={InvoiceSearch} />} />
          <Route exact path="/invoicing/:id" element={<ProtectedRoute component={InvoicePreview} />} />
          <Route exact path="/reports" element={<ProtectedRoute component={Reports} />} />
          <Route exact path="/patients/upload" element={<ProtectedRoute component={Upload} />} />
          <Route exact path="/patients/upload/processed" element={<ProtectedRoute component={UploadProcessed} />} />
          <Route exact path="/patients/search" element={<ProtectedRoute component={PatientSearch} />} />
          <Route exact path="/patients/new" element={<ProtectedRoute component={PatientProfile} />} />
          <Route exact path="/patients/:id" element={<ProtectedRoute component={PatientProfile} />} />
          <Route exact path="/patients/:id/interactive" element={<ProtectedRoute component={InteractiveForm} />} />
          <Route exact path="/patients/:id/case/:caseId" element={<ProtectedRoute component={PatientCase} />} />
        </Routes>
      )}
      <ScrollRestoration />
      <div className="footer" />
      {showPracticeSelectModal ? <PracticeSelect practiceRef={practice} /> : null}
    </div>
  );
}

export default App;
