import './style.css';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { selectUserName } from '../../state/userSlice';
import Notification from '../Notification/component';
import PatientTable from '../PatientTable/component';

export default function Dashboard(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useSelector(selectUserName);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (location.state && location.state.showSuccess) {
      setShowSuccess(location.state.showSuccess);
      navigate(location.pathname, { replace: true });
    }
  }, []);

  const greetings = () => {
    const today = new Date();
    const currentHour = today.getHours();
    if (currentHour < 12) {
      return 'Good morning';
    }
    if (currentHour < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  };

  return (
    <Container className="dashboard">
      <Notification errors={errors} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      <div className="header">
        <h1>
          {greetings()}
          {userName ? `, ${userName.split(' ')[0]}!` : '!'}
        </h1>
        <div className="table-options">
          <NavLink to="/patients/search">
            <Button variant="contained">Find or Add Patient</Button>
          </NavLink>
        </div>
      </div>
      <PatientTable />
    </Container>
  );
}
