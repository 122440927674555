import { configureStore } from '@reduxjs/toolkit';

import icFormSlice from './icFormSlice';
import invoiceSlice from './invoiceSlice';
import patientSlice from './patientSlice';
import systemSlice from './systemSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    patient: patientSlice,
    system: systemSlice,
    user: userSlice,
    icForm: icFormSlice,
    invoice: invoiceSlice,
  },
});

export default store;
