import './style.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader';
import { selectPatients } from '../../state/patientSlice';
import { selectLoading } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import Notification from '../Notification/component';
import CollapsibleRow from './CollapsibleRow';
import TableFilter from './TableFilters';

export default function PatientTable(props) {
  const loading = useSelector(selectLoading);
  const [errors, setErrors] = useState([]);
  const userType = useSelector(selectUserType);
  const patients = useSelector(selectPatients);

  return (
    <div className="patient-table">
      <TableFilter />
      <TableContainer>
        <Notification errors={errors} />
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" className="sticky">
                Patient Name
              </TableCell>
              <TableCell align="center" className="sticky second-col">
                Discharge Date
              </TableCell>
              <TableCell align="center">First Contact Date</TableCell>
              <TableCell align="center">IC-form Status</TableCell>
              <TableCell align="center">Actual Follow-up</TableCell>
              {userType === 'kouper' ? <TableCell align="center">Billing Status</TableCell> : null}
              {userType === 'kouper' ? (
                <TableCell align="center" className="notes">
                  Notes
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? null
              : patients.map((patient, index) => (
                  <CollapsibleRow
                    key={patient.id}
                    index={index}
                    patient={patient}
                    patients={patients}
                    setErrors={setErrors}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="footnote">{loading ? <Loader /> : 'Looking for more? Try a different filter...'}</div>
    </div>
  );
}
