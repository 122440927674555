import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import React, { useEffect, useState } from 'react';

import BaymaxApi from '../../BaymaxApi';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  maxHeight: '1000px',
  bgcolor: 'white',
  boxShadow: 24,
  padding: 4,
};

export default function ApptModal(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const handleClose = () => props.setShowModal(false);
  const handleSave = async () => {
    const token = await getAccessTokenSilently();
    let apptInfo;

    if (props.appointmentId) {
      apptInfo = await updateAppointment(token);
    } else {
      apptInfo = await bookAppointment(token);
    }

    props.setForm({
      ...props.form,
      patient: {
        ...props.form.patient,
        appointment: {
          ...props.form.patient.appointment,
          ...apptInfo,
        },
      },
    });
    props.fetchPatientAppointments(props.patientId);
    props.setShowModal(false);
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      const token = await getAccessTokenSilently();
      const appointments = await BaymaxApi.getAppointmentsByProvider(props.providerId, props.form.patient.id, token);
      setAppointments(appointments);
      setLoading(false);
    };

    if (!appointments.length) {
      fetchAppointments();
    }

    const availableTimes = appointments.filter((appt) => appt.date === dayjs(date).format('MM/DD/YYYY'));

    setAvailableTimes(availableTimes);
  }, [date]);

  const bookAppointment = async (token) => {
    let apptInfo;

    try {
      apptInfo = await BaymaxApi.bookAppointment(
        {
          athenaId: selectedAppointment.appointmentid,
          patientId: props.form.patient.id,
          providerId: props.form.patient.tcmProvider.id,
          date: selectedAppointment.date,
          startTime: selectedAppointment.starttime,
          duration: selectedAppointment.duration,
          appointmentTypeId: selectedAppointment.appointmenttypeid,
        },
        token
      );
    } catch (err) {
      props.setErrors(err);
    }

    return apptInfo;
  };

  const updateAppointment = async (token) => {
    let apptInfo;

    try {
      apptInfo = await BaymaxApi.updateAppointment(
        props.appointmentId,
        {
          athenaId: selectedAppointment.appointmentid,
          patientId: props.form.patient.id,
          providerId: props.form.patient.tcmProvider.id,
          date: selectedAppointment.date,
          startTime: selectedAppointment.starttime,
          duration: selectedAppointment.duration,
          appointmentTypeId: selectedAppointment.appointmenttypeid,
        },
        token
      );
    } catch (err) {
      props.setErrors(err);
    }

    return apptInfo;
  };

  const renderDay = (date, selectedDates, pickersDayProps) => {
    const hasAppointmentOnDay = appointments.find((apt) => apt.date === dayjs(date).format('MM/DD/YYYY'));

    if (hasAppointmentOnDay) {
      return (
        <PickersDay {...pickersDayProps}>
          <div className="availableDate">
            {dayjs(date).format('D')}
            <span className="dot" />
          </div>
        </PickersDay>
      );
    }

    return <PickersDay {...pickersDayProps} />;
  };

  return (
    <Modal open onClose={handleClose}>
      <Box sx={style} className="appt-modal">
        {loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : appointments.length ? (
          <div>
            <h3>Choose Provider Appointment Date</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={date}
                onChange={(newDate) => {
                  setDate(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
                renderDay={renderDay}
              />
            </LocalizationProvider>
            <h3>Pick a Time</h3>
            <div className="times">
              {availableTimes.map((appt) => (
                <Button
                  key={appt.appointmentid}
                  className={`time ${appt.appointmentid === selectedAppointment.appointmentid ? 'selected' : ''}`}
                  onClick={(e) => {
                    const selectedAppointment = appointments.find(
                      (appt) => appt.appointmentid === parseInt(e.target.value, 10)
                    );

                    setSelectedAppointment(selectedAppointment);
                  }}
                  value={appt.appointmentid}
                >
                  {appt.starttime}
                </Button>
              ))}
            </div>
            <p>All times are local to the facility.</p>
            <div className="buttons">
              <Button id="back-button" variant="contained" onClick={handleClose}>
                Back
              </Button>
              <Button variant="contained" onClick={handleSave} disabled={!(date && selectedAppointment.appointmentid)}>
                Continue
              </Button>
            </div>
          </div>
        ) : (
          "Currently, no availability within 14-days for this provider, please submit case in review, and the provider's office will support on follow-up booking."
        )}
      </Box>
    </Modal>
  );
}
