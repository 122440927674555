import ClearIcon from '@mui/icons-material/Clear';
import EventIcon from '@mui/icons-material/Event';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';

export default function DateInput(props) {
  const [date, setDate] = useState('');
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (props.value) {
      setDate(props.value);
    }
  }, [props.value]);

  const dateFormat = () => {
    if (props.view === 'month year') {
      return 'MMMM YYYY';
    }

    if (props.hideTime || (props.value && props.value.length < 11)) {
      return 'MM/DD/YYYY';
    }

    return 'MM/DD/YYYY hh:mm A';
  };

  const placeholder = () => {
    if (props.view === 'month year') {
      return 'Month + Year';
    }

    if (props.hideTime) {
      return 'MM/DD/YYYY';
    }

    return 'MM/DD/YYYY hh:mm';
  };

  const calendarView = props.view ? props.view.split(' ') : ['day'];

  const onKeyDown = (e) => {
    if (props.view === 'month') {
      e.preventDefault();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={'"month"'}
        views={calendarView}
        value={date}
        disableFuture={props.disableFuture || false}
        shouldDisableMonth={props.shouldDisableMonth}
        shouldDisableYear={props.shouldDisableYear}
        onChange={(value, keyboardInputValue) => {
          if (keyboardInputValue && props.debouncedChange) {
            if (value.format() === 'Invalid Date') {
              setInvalid(true);
            } else {
              props.debouncedChange(keyboardInputValue, props.name);
              setInvalid(false);
            }
          } else {
            props.handleChange(value, props.name);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <div className="clear">
              {props.value && !props.disabled ? (
                <IconButton
                  onClick={() => {
                    props.clearDate(props.name, null);
                    setDate(null);
                    setInvalid(false);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null}
            </div>
          ),
        }}
        inputFormat={dateFormat()}
        InputAdornmentProps={{ position: 'start' }}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            error={props.error || invalid}
            disabled={props.disabled}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder(),
            }}
            onKeyDown={onKeyDown}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
