import './style.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectBillingCodes, setSelectedBillingCodes } from '../../state/systemSlice';
import { BILLING_CODES } from '../../utils/constants/patientTable';

export default function BillingCodeFilter() {
  const dispatch = useDispatch();
  const selectedBillingCodes = useSelector(selectBillingCodes);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const billingCode = event.target.value;

    dispatch(setSelectedBillingCodes(billingCode));
  };

  const isChecked = (code) => {
    if (selectedBillingCodes.includes(Number(code))) {
      return true;
    }

    return false;
  };

  return (
    <div className="table-filter billing-filter">
      <Button variant="outlined" onClick={openMenu}>
        Billing Codes
        {selectedBillingCodes ? <p>{selectedBillingCodes.length ? `(${selectedBillingCodes.length})` : null}</p> : null}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <h3>Billing Codes</h3>
        {BILLING_CODES.map((code) => (
          <MenuItem key={code} value={code} onClick={handleChange}>
            <Checkbox checked={isChecked(code)} disabled />
            {code}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
