import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import React, { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import Notification from '../Notification/component';

export default function Upload() {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const openFileDialog = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    // Show error if same file uploaded
    if (file && file.name === fileObj.name) {
      setError(true);

      return;
    }

    setFile(fileObj);
    event.target.value = null;
  };

  const uploadFile = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append('file', file, file.name);
      const upload = await BaymaxApi.uploadPatients(formData, token);

      navigate(`/patients/upload/processed`, { state: { uploadId: upload.ingestionId } });
    } catch (err) {
      setLoading(false);
      setApiErrors(err);
    }
  };

  return (
    <Container className="upload">
      <Notification errors={apiErrors} />
      {error ? (
        <Alert
          severity="error"
          onClose={() => {
            setError(false);
          }}
        >
          File already added
        </Alert>
      ) : null}

      <h1>Discharges</h1>
      <h3>Upload Discharge File</h3>

      <div className="file-upload">
        <IconButton onClick={openFileDialog}>
          <AddCircleOutlineIcon />
          <input ref={inputRef} type="file" onChange={handleFileChange} />
        </IconButton>

        {file ? (
          <div className="file-info">
            <p>{file.name}</p>
            <Button variant="contained" className="button" onClick={uploadFile}>
              Upload
            </Button>
          </div>
        ) : null}

        {loading ? <CircularProgress /> : null}
      </div>

      <Button variant="contained" className="button">
        <NavLink to="/patients/upload/processed">Past Uploads</NavLink>
      </Button>
    </Container>
  );
}
