import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MenuItem } from '@mui/material';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import Loader from '../../components/Loader';
import { selectProvidersWithoutUnassigned } from '../../state/systemSlice';
import { parsePersonName } from '../../utils/helpers';
import Notification from '../Notification/component';
import CollapsibleRow from './CollapsibleRow';

export default function UploadProcessed() {
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [uploadIds, setUploadIds] = useState([]);
  const [processedRows, setProcessedRows] = useState([]);
  const [approvedRows, setApprovedRows] = useState([]);
  const providers = useSelector(selectProvidersWithoutUnassigned);

  const Highlights = {
    Error: 'error',
    Match: 'match',
    Warning: 'orange',
    Normal: '',
  };

  const fetchUploadIds = async () => {
    const token = await getAccessTokenSilently();
    const uploads = await BaymaxApi.getUploadIds(token);

    setUploadIds(uploads);
    setLoading(false);
  };

  const fetchRows = async (ingestionId) => {
    try {
      const token = await getAccessTokenSilently();
      const patients = await BaymaxApi.getUpload(ingestionId, token);

      setProcessedRows(patients.processed);
      setApprovedRows(patients.approved);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUploadIds();

    if (location.state && location.state.uploadId) {
      const ingestionId = location.state.uploadId;

      setSelectedId(ingestionId);
      fetchRows(ingestionId);
    }
  }, []);

  const editCell = (event) => {
    const { index } = event.target.dataset;
    const { key } = event.target.dataset;
    const rowsCopy = processedRows.slice();
    const editedRow = rowsCopy[index];

    editedRow[key] = event.target.value;
    rowsCopy[index] = editedRow;
    setProcessedRows(rowsCopy);
  };

  const approveRow = async (index) => {
    const row = processedRows[index];
    const approvedCopy = approvedRows.slice();
    const processedCopy = processedRows.slice();
    const payload = {
      id: row.id,
      dob: row.dob,
      phone: row.phone,
      state: 'approved',
    };

    try {
      const token = await getAccessTokenSilently();

      await BaymaxApi.updateIngestionStatus(selectedId, payload, token);
      approvedCopy.push(row);
      processedCopy.splice(index, 1);
      setProcessedRows(processedCopy);
      setApprovedRows(approvedCopy);
    } catch (error) {
      setErrors(error);
    }
  };

  const unapproveRow = async (index) => {
    const row = approvedRows[index];
    const approvedCopy = approvedRows.slice();
    const processedCopy = processedRows.slice();

    try {
      const token = await getAccessTokenSilently();
      const payload = { id: row.id, state: 'extracted' };

      await BaymaxApi.updateIngestionStatus(selectedId, payload, token);
      approvedCopy.splice(index, 1);
      processedCopy.push(row);
      setProcessedRows(processedCopy);
      setApprovedRows(approvedCopy);
    } catch (error) {
      setErrors(error);
    }
  };

  const getUpload = async (event) => {
    setLoading(true);
    setSelectedId(event.target.value);

    try {
      const token = await getAccessTokenSilently();
      const patients = await BaymaxApi.getUpload(event.target.value, token);

      setProcessedRows(patients.processed);
      setApprovedRows(patients.approved);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const refresh = async () => {
    if (selectedId) {
      setLoading(true);
      await fetchRows(selectedId);
    }
  };

  const dispHighlight = (text) => {
    const eligibleDisp = ['HOM', 'HHS', 'HFF', 'HOMF'];
    const disp = text ? text.trim() : undefined;
    if (disp) {
      return eligibleDisp.includes(disp) ? Highlights.Match : Highlights.Normal;
    }
    return Highlights.Error;
  };

  const pcpHighlight = (text) => {
    if (text && text.trim()) {
      const { firstName, lastName } = parsePersonName(text);
      const pcp =
        firstName && lastName
          ? providers.filter(
              (p) =>
                p.firstName.toLowerCase().includes(firstName.toLowerCase()) &&
                p.lastName.toLowerCase().includes(lastName.toLowerCase())
            )
          : [];

      if (text.trim() === 'NO PCP') return Highlights.Match;
      if (!(firstName && lastName)) return Highlights.Normal;
      if (pcp.length > 0) return Highlights.Match;
    }

    return Highlights.Normal;
  };

  return (
    <Container className="upload-processed">
      <h1>Discharge List</h1>

      <div className="table-options">
        <FormControl>
          <Select autoWidth value={selectedId} onChange={getUpload}>
            {uploadIds.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedId ? (
          <div className="refresh">
            {loading ? (
              <Loader />
            ) : (
              <IconButton onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            )}
          </div>
        ) : null}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="section">
            <h1>
              Approved Patients <span>{approvedRows.length ? `(${approvedRows.length})` : null}</span>
            </h1>
            <Notification errors={errors} />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">EHR ID</TableCell>
                    <TableCell align="center">DoB</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Room</TableCell>
                    <TableCell align="center">PCP</TableCell>
                    <TableCell align="center">Disp</TableCell>
                    <TableCell align="center">Payer</TableCell>
                    <TableCell align="center" className="notes">
                      Notes
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvedRows.map((row, index) => (
                    <CollapsibleRow
                      key={row.id}
                      row={row}
                      index={index}
                      selectedId={selectedId}
                      approvedRows={approvedRows}
                      setApprovedRows={setApprovedRows}
                      unapproveRow={unapproveRow}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="section">
            <h1>
              Uploaded Patients <span>{processedRows.length ? `(${processedRows.length})` : null}</span>
            </h1>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">EHR ID</TableCell>
                    <TableCell align="center">DoB</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Room</TableCell>
                    <TableCell align="center">PCP</TableCell>
                    <TableCell align="center">Disp</TableCell>
                    <TableCell align="center">Payer</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedRows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Name"
                          data-index={index}
                          data-key="name"
                          onChange={editCell}
                          value={row.name}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Meditech"
                          data-index={index}
                          data-key="meditechId"
                          onChange={editCell}
                          value={row.meditechId}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Needs Date"
                          data-index={index}
                          data-key="dob"
                          onChange={editCell}
                          value={row.dob}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Needs Phone"
                          data-index={index}
                          data-key="phone"
                          onChange={editCell}
                          value={row.phone}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Discharge Date"
                          data-index={index}
                          data-key="date"
                          onChange={editCell}
                          value={row.date}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Room"
                          data-index={index}
                          data-key="roomBed"
                          onChange={editCell}
                          value={row.roomBed}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="?"
                          data-index={index}
                          data-key="pcp"
                          onChange={editCell}
                          value={row.pcp}
                          className={pcpHighlight(row.pcp)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Disp"
                          data-index={index}
                          data-key="disp"
                          onChange={editCell}
                          value={row.disp}
                          className={dispHighlight(row.disp)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          placeholder="Payer"
                          data-index={index}
                          data-key="payer"
                          onChange={editCell}
                          value={row.payer}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            approveRow(index);
                          }}
                        >
                          <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Container>
  );
}
