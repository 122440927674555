import '../../styles/components/AuditHistory.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import BaymaxApi from '../../BaymaxApi';
import Loader from '../../components/Loader';
import { selectCurrentPractice } from '../../state/systemSlice';
import { formatDate } from '../../utils/helpers';

export default function AuditHistory(props) {
  const { getAccessTokenSilently } = useAuth0();
  const currentPractice = useSelector(selectCurrentPractice);
  const { timezone } = currentPractice;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [auditHistory, setAuditHistory] = useState([]);

  const fetchAuditHistory = async (patientId) => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const history = await BaymaxApi.getAuditHistory(patientId, props.type, token);

      setAuditHistory(history);
      setShowHistory(true);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getModalContent = (event) => {
    setModalContent(event);
    setShowModal(true);
  };

  return (
    <div className="audit-history">
      {showHistory ? (
        <div className="table">
          <h3>Audit History:</h3>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {auditHistory.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>{event.action}</TableCell>
                    <TableCell>{event.user}</TableCell>
                    <TableCell>{formatDate(event.ts, timezone)}</TableCell>
                    <TableCell>
                      <Button onClick={() => getModalContent(event)}>View Actions</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <Button onClick={() => fetchAuditHistory(props.patientId)}>Audit History</Button>
      )}

      <Dialog className="audit-history-modal" open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>
          {modalContent.action} - {modalContent.user} - {formatDate(modalContent.ts, timezone)}
        </DialogTitle>
        <DialogContent>
          <textarea value={JSON.stringify(modalContent.stateInTime, null, 2)} readOnly />
        </DialogContent>
      </Dialog>
    </div>
  );
}
