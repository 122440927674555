import { isEmpty } from 'lodash';

import BaymaxApi from '../BaymaxApi';
import store from '../state/store';
import { setProviders } from '../state/systemSlice';

export const fetchProviders = async (forceFetch, token) => {
  const state = store.getState();
  const providersInStore = state.system.providers;

  if (isEmpty(providersInStore) || forceFetch) {
    const providers = await BaymaxApi.getProviders(token);
    const providersWithoutUnassigned = providers.filter((p) => p.id !== 1);

    store.dispatch(setProviders({ providers, providersWithoutUnassigned }));
  }
};
