import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import Loader from '../../components/Loader';
import Notification from '../Notification/component';

export default function Reconcile() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    reconcile();
  }, []);

  const reconcile = async () => {
    try {
      const token = await getAccessTokenSilently();
      const changeEvents = await BaymaxApi.reconcile(token);

      setEvents(changeEvents);
    } catch (error) {
      setErrors(error);
    }

    setLoading(false);
  };

  return (
    <div className="reconcile">
      <Notification errors={errors} />
      {loading ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Last Modified By</TableCell>
                <TableCell>Last Modified Date</TableCell>
                <TableCell>Change</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.patientId}>
                  <TableCell>{event.patientId}</TableCell>
                  <TableCell>
                    <Link to={`/patients/${event.patientId}`} target="_blank">
                      {event.patientName}
                    </Link>
                  </TableCell>
                  <TableCell>{event.status}</TableCell>
                  <TableCell>{event.priority}</TableCell>
                  <TableCell>{event.assignedTo}</TableCell>
                  <TableCell>{event.lastmodifieduser}</TableCell>
                  <TableCell>{event.lastmodifieddate}</TableCell>
                  <TableCell>{event.change}</TableCell>
                  <TableCell>
                    <Link to={`/patients/${event.patientId}/interactive`} target="_blank">
                      <Button variant="contained" className="button">
                        Form
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
