import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import CaseActionNotes from '../../components/CaseActionNotes';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import RadioSelect from '../../components/RadioSelect';
import { selectProvidersWithoutUnassigned } from '../../state/systemSlice';
import { YES_NO } from '../../utils/constants/icform';
import { PATIENT_AUDIT_HISTORY_TYPES, PATIENT_CASE_TYPES } from '../../utils/constants/patient';
import { bool2str, str2bool } from '../../utils/helpers';
import AuditHistory from '../InteractiveForm/AuditHistory';
import Notification from '../Notification/component';

export default function PatientCase() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const providers = useSelector(selectProvidersWithoutUnassigned);
  const [loading, setLoading] = useState(false);
  const [providerStaff, setProviderStaff] = useState([]);
  const [errors, setErrors] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [patientCase, setPatientCase] = useState({
    dischargeDate: '',
    providerId: '',
    needReview: '',
    subject: '',
    notes: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    alternativePhone: '',
    urgent: null,
  });

  const caseId = location?.state?.caseId || location.pathname.split('/').pop();

  const fetchCase = async (id) => {
    const token = await getAccessTokenSilently();
    const patientCase = await BaymaxApi.getPatientCase(id, token);
    const caseSubmitted = patientCase.status === 'submit';

    if (caseSubmitted) {
      const providerStaff = providers.find((provider) => provider.id === patientCase.providerId);

      setProviderStaff(providerStaff.staff);
    }

    setPatientCase(patientCase);
    setIsSubmitted(caseSubmitted);
  };

  useEffect(() => {
    if (location.state?.patientCase) {
      setPatientCase(location.state.patientCase);
    } else {
      fetchCase(caseId);
    }
  }, []);

  const setProvider = (e) => {
    setPatientCase({
      ...patientCase,
      providerId: parseInt(e.target.value, 10),
    });
  };

  const setFormValue = (name, value) => {
    if (value === patientCase[name]) {
      setPatientCase({ ...patientCase, [name]: '' });
    } else {
      setPatientCase({ ...patientCase, [name]: value });
      setValidationErrors({ ...validationErrors, [name]: '' });
    }
  };

  const getFormBody = () => ({
    providerId: patientCase.providerId,
    subject: patientCase.subject,
    notes: patientCase.notes,
    needReview: str2bool(patientCase.needReview),
    urgent: str2bool(patientCase.urgent),
  });

  const saveChanges = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      await BaymaxApi.updatePatientCase(patientCase.id, getFormBody(), token);
      navigate(`/patients/${patientCase.patientId}`, { state: { showSuccess: true } });
    } catch (err) {
      setErrors(err);
      setLoading(false);
    }
  };

  const submitToAthena = async () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();

        await BaymaxApi.submitPatientCase(patientCase.id, getFormBody(), token);
        navigate(`/patients/${patientCase.patientId}`, { state: { showSuccess: true } });
      } catch (err) {
        setErrors(err);
        setLoading(false);
      }
    } else {
      window.scrollTo(0, 0);
      setErrors(['All required fields are not complete']);
    }
  };

  const handleValidation = () => {
    let isValid = true;
    const errors = {};

    if (!patientCase.providerId) {
      isValid = false;
      errors.providerId = true;
    }

    if (patientCase.needReview === null || patientCase.needReview === '') {
      isValid = false;
      errors.needReview = 'Required';
    }

    if (patientCase.urgent === null || patientCase.urgent === '') {
      isValid = false;
      errors.urgent = 'Required';
    }

    if (!patientCase.subject) {
      isValid = false;
      errors.subject = true;
    }

    if (!patientCase.notes) {
      isValid = false;
      errors.notes = true;
    }

    setValidationErrors(errors);
    return isValid;
  };

  return (
    <Container className="patient-case">
      {loading ? (
        <Loader />
      ) : (
        <div className="form-fields">
          <div className="patient-info">
            <p>
              <b>Name:</b> {patientCase.lastName}, {patientCase.firstName}
            </p>
            <p>
              <b>Date of Birth:</b> {patientCase.dateOfBirth}
            </p>
            <p>
              <b>Primary Phone:</b> {patientCase.phone}
            </p>
            <p>
              <b>Secondary Phone:</b> {patientCase.alternativePhone}
            </p>
          </div>

          <h2>Patient Case</h2>

          <Notification errors={errors} />

          <div className="discharge single-line section">
            <h3>Date of Discharge:</h3>
            <DateInput name="dischargeDate" value={patientCase.dischargeDate} hideTime disabled />
          </div>

          <div className="tcm single-line section">
            <h3>TCM Provider:</h3>
            <FormControl>
              <Select value={patientCase.providerId} onChange={setProvider} error={validationErrors.providerId}>
                {providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="review single-line section">
            <h3>Case needs review?</h3>
            <RadioSelect
              name="needReview"
              value={bool2str(patientCase.needReview)}
              options={YES_NO}
              validationMessage={validationErrors.needReview}
              handleChange={setFormValue}
              direction="row"
              convertToBool
            />
          </div>

          <div className="review single-line section">
            <h3>Mark case urgent?</h3>
            <RadioSelect
              name="urgent"
              value={bool2str(patientCase.urgent)}
              options={YES_NO}
              validationMessage={validationErrors.urgent}
              handleChange={setFormValue}
              direction="row"
              convertToBool
            />
          </div>

          <div className="section case-subject">
            <h3>Case Subject:</h3>
            <TextField
              value={patientCase.subject}
              onChange={(e) => {
                setPatientCase({
                  ...patientCase,
                  subject: e.target.value,
                });
              }}
              error={validationErrors.subject}
            />
          </div>

          <div className="notes section">
            <TextField
              label="Notes"
              multiline
              rows={6}
              error={validationErrors.notes}
              value={patientCase.notes}
              onChange={(e) => {
                setPatientCase({
                  ...patientCase,
                  notes: e.target.value,
                });
              }}
            />
          </div>

          {isSubmitted ? (
            <CaseActionNotes caseId={caseId} staff={providerStaff} caseType={PATIENT_CASE_TYPES.COMMON} />
          ) : (
            <div className="save">
              <Button variant="contained" onClick={saveChanges} disabled={isSubmitted || loading}>
                Save Changes
              </Button>
              <Button variant="contained" onClick={submitToAthena} disabled={isSubmitted || loading}>
                Submit
              </Button>
            </div>
          )}

          {isSubmitted ? (
            <AuditHistory patientId={patientCase.patientId} type={PATIENT_AUDIT_HISTORY_TYPES.CASE} />
          ) : null}
        </div>
      )}
    </Container>
  );
}
