import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import { INVOICE_STATUS } from '../../utils/constants/invoice';
import InvoiceSummary from './InvoiceSummary';

export default function InvoiceDetail(props) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { invoice } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    issueDate: '',
    dueDate: '',
  });

  const approveInvoice = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const payload = {
        periodStart: props.startDate,
        periodEnd: props.endDate,
        issueDate: invoiceDetails.issueDate || invoice.issueDate,
        dueDate: invoiceDetails.dueDate || invoice.dueDate,
        description: 'Kouper transitions of care initiative',
        invoiceNumber: invoice.invoiceNumber,
      };

      await BaymaxApi.approveInvoice(payload, token);
      navigate('/invoicing');
    } catch (err) {
      console.log(err);
    }
  };

  const filterInvoicedPatients = (patients) => {
    return patients
      .filter((patient) => patient.status === INVOICE_STATUS.invoiced)
      .map((item) => {
        const { id, reason, status, ...rest } = item;
        return { ...rest };
      });
  };

  const exportInvoice = async () => {
    setLoading(true);
    const headers = [['Facility', 'Department', 'Patient ID', 'Service Date', 'Billing Code', 'Provider', 'Charge']];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    const approvedInvoices = filterInvoicedPatients(invoice.details);

    XLSX.utils.sheet_add_aoa(ws, headers);
    XLSX.utils.sheet_add_json(ws, approvedInvoices, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${invoice.invoiceNumber}.xlsx`);
    setLoading(false);
  };

  const voidInvoice = async () => {
    try {
      const token = await getAccessTokenSilently();

      await BaymaxApi.voidInvoice(props.invoice.id, token);
      navigate('/invoicing');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (value, name) => {
    if (value && value.format() !== 'Invalid Date') {
      setInvoiceDetails({
        ...invoiceDetails,
        [name]: value.format('MM/DD/YYYY'),
      });
    } else {
      setInvoiceDetails({
        ...invoiceDetails,
        [name]: '',
      });
    }
  };

  const clearDate = (name) => {
    setInvoiceDetails({
      ...invoiceDetails,
      [name]: '',
    });
  };

  const getInvoicePeriod = () => {
    if (invoice.isDraft) {
      return `${props.startDate} - ${props.endDate}`;
    }

    return `${invoice.periodStart} - ${invoice.periodEnd}`;
  };

  const invoiceLink = (row) => {
    if (row.status === 'Invoiced' && !isEmpty(row.invoice)) {
      return (
        <Link to={`/invoicing/${row.invoice.id}`} state={{ invoiceId: row.invoice.id }}>
          {row.status}
        </Link>
      );
    }

    return row.status;
  };

  return isEmpty(invoice) ? null : (
    <div className="invoice-detail">
      {props.hideInfo ? null : (
        <div className="info">
          <div className="section">
            <DateInput
              name="issueDate"
              label="Date of Issue"
              value={invoiceDetails.issueDate || invoice.issueDate}
              handleChange={handleDateChange}
              clearDate={clearDate}
              hideTime
            />
            <TextField
              label="Invoice Number"
              value={invoice.invoiceNumber}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Amount Due"
              value={invoice.amount}
              InputProps={{
                readOnly: true,
              }}
            />
            <DateInput
              name="dueDate"
              label="Due Date"
              value={invoiceDetails.dueDate || invoice.dueDate}
              handleChange={handleDateChange}
              clearDate={clearDate}
              hideTime
            />
          </div>
          <div className="section">
            <TextField
              className="description"
              label="Description"
              value="Kouper transitions of care initiative"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className="invoice-period"
              label="Invoice Period"
              value={getInvoicePeriod()}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className="facility-count"
              label="Facility Count"
              value={invoice.summary.length}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        </div>
      )}

      {props.hideSummary ? null : <InvoiceSummary summary={invoice.summary} />}

      <TableContainer className="detail-table">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Facility</TableCell>
              <TableCell>Service Date</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Billing Code</TableCell>
              <TableCell>Patient ID</TableCell>
              <TableCell>Charge</TableCell>
              <TableCell>Invoice Status</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.details.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`detail-${index}`}>
                <TableCell>
                  <Link to={`/patients/${row.id}`} state={{ patientId: row.id }}>
                    {index + 1}
                  </Link>
                </TableCell>
                <TableCell>{row.practiceName}</TableCell>
                <TableCell>{row.serviceDate}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.renderingProvider}</TableCell>
                <TableCell>{row.cptCode}</TableCell>
                <TableCell>{row.athenaId}</TableCell>
                <TableCell>{row.charge}</TableCell>
                <TableCell>{invoiceLink(row)}</TableCell>
                <TableCell>{row.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.hideOptions ? null : (
        <div className="options">
          {invoice.isDraft ? (
            <Button variant="contained" onClick={approveInvoice} disabled={loading}>
              Approve
            </Button>
          ) : (
            <Button variant="contained" onClick={exportInvoice} disabled={loading}>
              Export
            </Button>
          )}
          {invoice.status === 'void' || invoice.isDraft ? null : (
            <Button variant="outlined" className="danger" onClick={() => setShowModal(true)} disabled={loading}>
              Void
            </Button>
          )}
        </div>
      )}

      <Dialog open={showModal} className="confirmation-modal">
        <DialogTitle>Void the current invoice?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={voidInvoice}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
