import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import querystring from 'querystring';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import { extractNameDobDc, phoneFormat } from '../../utils/helpers';

export default function CollapsibleRow(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { row, index, selectedId, approvedRows, setApprovedRows, unapproveRow } = props;
  const [open, setOpen] = useState(false);
  const [initialNote, setInitialNote] = useState(row.notes);

  const updateNote = (event) => {
    const rows = approvedRows.slice();

    rows[index].notes = event.target.value;
    setApprovedRows(rows);
  };

  const saveEdit = async () => {
    const token = await getAccessTokenSilently();
    const payload = { id: row.id, notes: row.notes };

    await BaymaxApi.updateIngestionStatus(selectedId, payload, token);

    setInitialNote(row.notes);
    setOpen(false);
  };

  const cancelEdit = () => {
    const rows = approvedRows.slice();

    rows[index].notes = initialNote;
    setApprovedRows(rows);
    setOpen(false);
  };

  return (
    <React.Fragment key={props.id}>
      <TableRow className={row.state === 'ingested' ? 'ingested' : ''}>
        <TableCell align="center">{row.id}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.meditechId}</TableCell>
        <TableCell align="center">{row.dob}</TableCell>
        <TableCell align="center">{phoneFormat(row.phone)}</TableCell>
        <TableCell align="center">{row.date}</TableCell>
        <TableCell align="center">{row.roomBed}</TableCell>
        <TableCell align="center">{row.pcp}</TableCell>
        <TableCell align="center">{row.disp}</TableCell>
        <TableCell align="center">{row.payer}</TableCell>
        <TableCell align="center" timeout="auto">
          <Tooltip
            title={
              <div
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: '14px',
                }}
              >
                {row.notes}
              </div>
            }
            placement="bottom"
            arrow
          >
            <IconButton sx={{ color: row.notes?.length ? 'black' : 'gray' }} onClick={() => setOpen(!open)}>
              <TextSnippetOutlinedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {row.state === 'ingested' ? null : (
            <div className="actions">
              <IconButton
                onClick={() => {
                  unapproveRow(index);
                }}
              >
                <RemoveCircleOutlineOutlinedIcon />
              </IconButton>
              <NavLink
                to={`/patients/search?${querystring.stringify({
                  name: row.name,
                  dob: row.dob,
                  meditechId: row.meditechId,
                  phone: row.phone,
                  ingestionId: selectedId,
                  patientId: row.id,
                  dc: row.date,
                })}`}
                state={{ uploadedPatient: extractNameDobDc(row), uploadId: selectedId }}
              >
                <Button variant="contained" className="button">
                  Search
                </Button>
              </NavLink>
            </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow className="collapse">
        <TableCell colSpan={11}>
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>
              <TextField multiline rows={3} value={row.notes ?? ''} onChange={updateNote} />
              <div className="actions">
                <CheckIcon className="check" onClick={saveEdit} />
                <CloseIcon className="close" onClick={cancelEdit} />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
