export const PATIENT_STATUS = [
  { label: 'All', value: 'all' },
  { label: 'Ready for Call', value: 'ready_ic' },
  { label: 'Ready for Follow-up', value: 'ready_followup' },
  { label: 'Billed', value: 'billed' },
  { label: 'Not Eligible', value: 'not_eligible' },
  { label: 'Not Captured', value: 'not_captured' },
];

export const BILLING_CODES = ['99496', '99495', '99215', '99214', '99213', '99212'];

export default PATIENT_STATUS;
