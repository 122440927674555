import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { selectOrgName, selectUserType } from '../../state/userSlice';
import UserDropdown from './UserDropdown';

export default function Navbar() {
  const orgName = useSelector(selectOrgName);
  const userType = useSelector(selectUserType);
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  if (isAuthenticated) {
    return (
      <Box>
        <AppBar className="nav" sx={{ bgcolor: 'white', boxShadow: 0 }} position="static">
          <Toolbar>
            <NavLink to="/dashboard">
              <img src={logo} alt="Kouper Health" />
            </NavLink>
            <Button
              className="link"
              onClick={() => {
                navigate('patients/upload');
              }}
            >
              Upload
            </Button>
            {userType === 'kouper' ? (
              <Button
                className="link"
                onClick={() => {
                  navigate('reports');
                }}
              >
                Reports
              </Button>
            ) : null}
            {userType === 'kouper' ? (
              <Button
                className="link"
                onClick={() => {
                  navigate('invoicing');
                }}
              >
                Invoicing
              </Button>
            ) : null}
            <UserDropdown />
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
