import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { capitalize, debounce, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import CaseActionNotes from '../../components/CaseActionNotes';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import RadioSelect from '../../components/RadioSelect';
import { getIcFormByPatientId } from '../../state/curryingSelectors/icFormCurryingSelectors';
import { setIcForm } from '../../state/icFormSlice';
import { selectCurrentPractice, selectProviders } from '../../state/systemSlice';
import {
  DISCHARGE_DESTINATION_TYPES,
  DISCHARGE_FACILITY_TYPES,
  POST_DISCHARGE_QUESTIONS,
  YES_NO,
  YES_NO_NOT_APPLICABLE,
} from '../../utils/constants/icform';
import { PATIENT_AUDIT_HISTORY_TYPES, PATIENT_CASE_TYPES } from '../../utils/constants/patient';
import { bool2str, convertToUtc, getCurrentTime, phoneFormat } from '../../utils/helpers';
import Notification from '../Notification/component';
import ApptModal from '../PatientProfile/ApptModal';
import AuditHistory from './AuditHistory';

export default function InteractiveForm(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const patientIdFromUrl = location.state ? location.state.id : location.pathname.split('/')[2];
  const providers = useSelector(selectProviders);
  const currentIcForm = useSelector((state) => getIcFormByPatientId(state, patientIdFromUrl));
  const currentPractice = useSelector(selectCurrentPractice);
  const { timezone } = currentPractice;
  const { dialpadNumber } = currentPractice;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState('');
  const [icFormStatus, setIcFormStatus] = useState('draft');
  const [showApptModal, setShowApptModal] = useState(false);
  const [patientAppointments, setPatientAppointments] = useState([]);
  const [providerStaff, setProviderStaff] = useState([]);
  const [form, setForm] = useState({
    id: null,
    athenaId: null,
    dischargeDate: '',
    dischargeFacilityType: '',
    dischargeDestination: '',
    pcp: '',
    specialist: '',
    specialistFollowupDate: '',
    tcmProviderId: '',
    notes: '',
    needReview: null,
    urgent: null,
    contactAttempts: {
      yes: '',
      noVoicemail: '',
      noUnable: '',
      noLast: '',
    },
    patient: {
      followupDate: '',
      label: '',
    },
    postDischargeQuestions: POST_DISCHARGE_QUESTIONS,
  });

  const fetchPatientAppointments = async (patientId) => {
    const token = await getAccessTokenSilently();
    const appointments = await BaymaxApi.getPatientAppointments(patientId, token);

    setPatientAppointments(appointments);
  };

  const questionsVersion = (version, status) => {
    if (version === 'v1' && status.endsWith('submit')) {
      return 'v1';
    }

    return 'v2';
  };

  const fetchIcForm = async (patientId) => {
    try {
      const token = await getAccessTokenSilently();
      const icForm = await BaymaxApi.getIcForm(patientId, token);

      setIcFormStatus(icForm.status);

      if (!currentIcForm) {
        dispatch(setIcForm(icForm));
      }

      const { postDischargeQuestions, status } = icForm;
      const combinedPostDischargeQuestions = {
        ...POST_DISCHARGE_QUESTIONS,
        ...postDischargeQuestions,
        serviceNeeds: {
          ...POST_DISCHARGE_QUESTIONS.serviceNeeds,
          ...postDischargeQuestions.serviceNeeds,
        },
        version: questionsVersion(postDischargeQuestions.version, status),
      };

      setForm({
        ...form,
        contactAttempts: icForm.contactAttempts,
        dischargeDate: icForm.patient.dischargeDate || '',
        dischargeDestination: icForm.dischargeDestination || '',
        dischargeFacilityType: icForm.dischargeFacilityType || '',
        needReview: icForm.needReview,
        urgent: icForm.urgent,
        notes: icForm.notes || '',
        pcp: icForm.pcp || (icForm.patient.provider ? icForm.patient.provider.displayName : ''),
        postDischargeQuestions: combinedPostDischargeQuestions,
        specialist: icForm.specialist || '',
        specialistFollowupDate: icForm.specialistFollowupDate || '',
        tcmProviderId: icForm.tcmProviderId,
        patient: { ...icForm.patient },
        patientId: icForm.patientId,
        id: icForm.id,
      });

      if (status.endsWith('submit')) {
        setIsSubmitted(icForm.status);
      }

      setLoading(false);
    } catch (err) {
      setErrors(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (form.id === null && isEmpty(patientAppointments)) {
      fetchIcForm(patientIdFromUrl);
      fetchPatientAppointments(patientIdFromUrl);
    }

    if (!isEmpty(providers) && form.tcmProviderId) {
      getProviderStaff(providers, form.tcmProviderId);
    }
  }, [form.tcmProviderId]);

  const getProviderStaff = (providers, providerId) => {
    const provider = providers.find((provider) => provider.id === providerId);

    setProviderStaff(provider.staff);
  };

  const setFormValue = (name, value) => {
    if (value === form[name]) {
      setForm({ ...form, [name]: '' });
    } else {
      setForm({ ...form, [name]: value });
      setValidationErrors({ ...validationErrors, [name]: '' });
    }
  };

  const setDischargeQuestionsValue = (name, value) => {
    if (value === form.postDischargeQuestions[name]) {
      setForm({
        ...form,
        postDischargeQuestions: {
          ...form.postDischargeQuestions,
          [name]: '',
        },
      });
    } else {
      setForm({
        ...form,
        postDischargeQuestions: {
          ...form.postDischargeQuestions,
          [name]: value,
        },
      });
      setValidationErrors({ ...validationErrors, [name]: '' });
    }
  };

  const handleServiceNeeds = (event) => {
    const { name, value } = event.target;

    if (form.postDischargeQuestions.serviceNeeds[name].length) {
      setForm({
        ...form,
        postDischargeQuestions: {
          ...form.postDischargeQuestions,
          serviceNeeds: {
            ...form.postDischargeQuestions.serviceNeeds,
            [name]: '',
          },
        },
      });
    } else {
      setForm({
        ...form,
        postDischargeQuestions: {
          ...form.postDischargeQuestions,
          serviceNeeds: {
            ...form.postDischargeQuestions.serviceNeeds,
            [name]: value,
          },
        },
      });
      setValidationErrors({ ...validationErrors, serviceNeeds: isServiceNeedsInvalid() });
    }
  };

  const setTcm = (e) => {
    const selectedTcm = providers.find((provider) => e.target.value === provider.id);

    setForm({
      ...form,
      tcmProviderId: parseInt(e.target.value, 10),
      patient: {
        ...form.patient,
        tcmProvider: selectedTcm,
      },
    });
  };

  const getFormBody = () => ({
    ...form,
    patient: {
      ...form.patient,
      dischargeDate: new Date(form.dischargeDate),
    },
    postDischargeQuestions: form.postDischargeQuestions,
  });

  const prepStateFormData = (form) => {
    form.patientId = patientIdFromUrl;
    form.status = icFormStatus;
    if (form.patient.dischargeDate) {
      // redux does not like class objects stored in state...it will still work but
      // will throw lots of console errors
      const stringifiedDate = form.patient.dischargeDate.toString();
      form.patient.dischargeDate = stringifiedDate;
    }
    return form;
  };

  const saveChanges = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const formBody = getFormBody();

    try {
      const updatedIcForm = await BaymaxApi.saveIcForm(location.state.id, formBody, token);

      const stateReadyForm = prepStateFormData(updatedIcForm);
      dispatch(setIcForm(stateReadyForm));

      navigate(`/patients/${location.state.id}`, {
        state: { showSuccess: true, patient: updatedIcForm.patient },
      });
    } catch (err) {
      setErrors(err);
      setLoading(false);
    }
  };

  const submitToAthena = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const formBody = getFormBody();
    const validation = handleValidation(formBody);

    if (validation.isValid) {
      try {
        await BaymaxApi.submitIcForm(location.state.id, validation.formBody, token);

        const stateReadyForm = prepStateFormData(formBody);
        dispatch(setIcForm(stateReadyForm));

        navigate(`/patients/${location.state.id}`, {
          state: { showSuccess: true, patient: form.patient },
        });
      } catch (err) {
        setErrors(err);
        setLoading(false);
      }
    } else {
      setErrors(['All required fields are not complete']);
      window.scrollTo(0, 0);
      setLoading(false);
    }
  };

  const isServiceNeedsInvalid = () => {
    const serviceNeeds = Object.values(form.postDischargeQuestions.serviceNeeds);

    if (serviceNeeds.some((need) => need.length)) {
      return false;
    }

    return true;
  };

  const handleValidation = (formBody) => {
    let isValid = true;
    const errors = {};

    const attempts = countContactAttempts();

    // if there is a successful attempt or no attempt at all
    if (attempts.yes > 0 || attempts.no + attempts.yes === 0) {
      if (attempts.no + attempts.yes === 0) {
        isValid = false;
        errors.checkInCall = 'Required';
      }

      if (!formBody.dischargeFacilityType) {
        isValid = false;
        errors.dischargeFacilityType = 'Required';
      }

      if (!formBody.dischargeDestination) {
        isValid = false;
        errors.dischargeDestination = 'Required';
      }

      if (!formBody.postDischargeQuestions.adjustmentToHome) {
        isValid = false;
        errors.adjustmentToHome = true;
      }

      if (!formBody.postDischargeQuestions.nutrition) {
        isValid = false;
        errors.nutrition = 'Required';
      }

      if (!formBody.postDischargeQuestions.mobility) {
        isValid = false;
        errors.mobility = 'Required';
      }

      if (!formBody.postDischargeQuestions.dischargeInstructionEducation) {
        isValid = false;
        errors.dischargeInstructionEducation = 'Required';
      }

      if (!formBody.postDischargeQuestions.prescription) {
        isValid = false;
        errors.prescription = 'Required';
      }

      if (!formBody.postDischargeQuestions.medicationCompliance) {
        isValid = false;
        errors.medicationCompliance = 'Required';
      }

      if (isServiceNeedsInvalid()) {
        isValid = false;
        errors.serviceNeeds = 'Required';
      }
    }
    // if more than 2 unsuccessful attempts, all fields optional except need review
    else if (attempts.no > 2) {
      formBody.followingDischargeInstruction = '';
      formBody.takingMedication = '';
    }
    // if only 1 unsuccessful attempt, same as more than 1 no and require another attempt date
    else if (attempts.no > 0) {
      isValid = false;
      errors.checkInCall = 'Required';
    }

    if (formBody.needReview === null || formBody.needReview === '') {
      isValid = false;
      errors.needReview = 'Required';
    }

    if (formBody.urgent === null || formBody.urgent === '') {
      isValid = false;
      errors.urgent = 'Required';
    }

    setValidationErrors(errors);

    return { isValid, formBody };
  };

  const countContactAttempts = () => {
    const contactAttempts = { ...form.contactAttempts };
    const yes = contactAttempts.yes ? 1 : 0;
    let no = 0;

    Object.keys(contactAttempts).forEach((key) => {
      if (contactAttempts[key] && key !== 'yes') {
        no += 1;
      }
    });

    return { yes, no };
  };

  const hasEmptyTime = (date) => {
    if (date.hour() === 0 && date.minute() === 0) {
      return true;
    }

    return false;
  };

  const saveContactDateTime = (date, name) => {
    setForm({
      ...form,
      contactAttempts: {
        ...form.contactAttempts,
        [name]: convertToUtc(date),
      },
    });
  };

  const debouncedHandleChange = debounce(async (date, name) => {
    date = dayjs(date).tz(timezone, true);

    if (date.format() !== 'Invalid Date') {
      handleContactDateChange(date, name);
    }
  }, 1000);

  const handleContactDateChange = (date, name) => {
    if (date && date.format() !== 'Invalid Date') {
      const prevSelectedDate = form.contactAttempts[name];

      if (prevSelectedDate) {
        const dateInTimezone = date.tz(timezone, true);

        saveContactDateTime(dateInTimezone, name);
      } else if (hasEmptyTime(date)) {
        saveContactDateTime(getCurrentTime(date), name);
      } else {
        saveContactDateTime(date, name);
      }
    } else if (date === null) {
      clearDate(name);
    }
  };

  const handleFormDateChange = (value, name) => {
    if (value && value.format() !== 'Invalid Date') {
      setForm({
        ...form,
        [name]: value.format('MM/DD/YYYY'),
      });
    } else {
      setForm({
        ...form,
        [name]: '',
      });
    }
  };

  const formatDate = (value) => {
    if (value) {
      if (value.length < 11) {
        return value;
      }

      return dayjs(value).tz(timezone).format('MM/DD/YYYY hh:mm a');
    }

    return '';
  };

  const clearDate = (name) => {
    if (form.contactAttempts[name]) {
      setForm({
        ...form,
        contactAttempts: {
          ...form.contactAttempts,
          [name]: null,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: null,
      });
    }
  };

  const appointmentStatusIcon = (status, statusVerbose) => {
    let icon;

    if (status === 'x') {
      icon = <EventBusyOutlinedIcon />;
    } else {
      icon = <EventAvailableOutlinedIcon />;
    }

    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              fontSize: '14px',
            }}
          >
            {statusVerbose}
          </div>
        }
        placement="bottom"
        arrow
      >
        {icon}
      </Tooltip>
    );
  };

  const dialpadHyperlink = (patientNumber) =>
    `dialpad://+1${patientNumber}?fromNumber=+1${dialpadNumber}&launchMinimode=0&confirmPhone=1`;

  const isAthenaSubmit = (submitStatus) => submitStatus && ['review_submit', 'submit'].includes(submitStatus);

  return (
    <Container className="interactiveForm">
      {loading ? (
        <Loader />
      ) : (
        <div className="form-fields">
          <Notification errors={errors} />
          <div className="patient-info">
            <p className="name">
              <b>Name:</b>
              <Link to={`/patients/${patientIdFromUrl}`}>
                {form.patient.lastName}, {form.patient.firstName}
              </Link>
            </p>
            <p>
              <b>Date of Birth:</b> {form.patient.dateOfBirth}
            </p>
            <p>
              <b>Primary Phone: </b>
              <a href={dialpadHyperlink(form.patient.phone)} rel="noreferrer">
                {phoneFormat(form.patient.phone)}
              </a>
            </p>
            <p>
              <b>Secondary Phone: </b>
              <a href={dialpadHyperlink(form.patient.alternativePhone)} rel="noreferrer">
                {phoneFormat(form.patient.alternativePhone)}
              </a>
            </p>
          </div>

          <div className="checkInCall section">
            <div className="heading">
              <h3>
                Patient/caregiver contacted <span>{validationErrors.checkInCall}</span>
              </h3>
              <p>All times are local to facility.</p>
            </div>
            <div className="row">
              <div className="info">
                {form.contactAttempts.yes ? <CheckIcon /> : <CircleIcon className="circle" />}
                <p>Yes - able to connect</p>
              </div>
              <DateInput
                name="yes"
                value={formatDate(form.contactAttempts.yes)}
                handleChange={handleContactDateChange}
                debouncedChange={debouncedHandleChange}
                clearDate={clearDate}
                disableFuture
              />
            </div>
            <div className="row">
              <div className="info">
                {form.contactAttempts.noVoicemail ? <CheckIcon /> : <CircleIcon className="circle" />}
                <p>No - unable to reach</p>
              </div>
              <DateInput
                name="noVoicemail"
                value={formatDate(form.contactAttempts.noVoicemail)}
                handleChange={handleContactDateChange}
                debouncedChange={debouncedHandleChange}
                clearDate={clearDate}
                disableFuture
              />
            </div>
            <div className="row">
              <div className="info">
                {form.contactAttempts.noUnable ? <CheckIcon /> : <CircleIcon className="circle" />}
                <p>No - unable to reach</p>
              </div>
              <DateInput
                name="noUnable"
                value={formatDate(form.contactAttempts.noUnable)}
                handleChange={handleContactDateChange}
                debouncedChange={debouncedHandleChange}
                clearDate={clearDate}
                disableFuture
              />
            </div>
            <div className="row">
              <div className="info">
                {form.contactAttempts.noLast ? <CheckIcon /> : <CircleIcon className="circle" />}
                <p>No - unable to reach</p>
              </div>
              <DateInput
                name="noLast"
                value={formatDate(form.contactAttempts.noLast)}
                handleChange={handleContactDateChange}
                debouncedChange={debouncedHandleChange}
                clearDate={clearDate}
                disableFuture
              />
            </div>
          </div>
          <div className="discharge single-line section">
            <h3>Date of discharge:</h3>
            <DateInput value={form.dischargeDate} hideTime disabled />
          </div>
          <div className="dischargeFacilityType section">
            <h3>
              Discharge from: <span>{validationErrors.dischargeFacilityType}</span>
            </h3>
            <RadioSelect
              name="dischargeFacilityType"
              value={capitalize(form.dischargeFacilityType)}
              options={DISCHARGE_FACILITY_TYPES}
              handleChange={setFormValue}
              direction="column"
            />
          </div>
          <div className="dischargeDestination section">
            <h3>
              Discharged to: <span>{validationErrors.dischargeDestination}</span>
            </h3>
            <RadioSelect
              name="dischargeDestination"
              value={capitalize(form.dischargeDestination)}
              options={DISCHARGE_DESTINATION_TYPES}
              handleChange={setFormValue}
              direction="column"
            />
          </div>
          <div className="postDischargeQuestions section">
            <h3>
              Post-discharge questions: <span>{validationErrors.postDischargeQuestions}</span>
            </h3>
            {form.postDischargeQuestions.version === 'v1' && isSubmitted ? null : (
              <div>
                <div className="row directionColumn">
                  <div className="question">
                    <p>How have you been since returning home?</p>
                  </div>
                  <TextField
                    multiline
                    rows={3}
                    className="notebox"
                    value={form.postDischargeQuestions.adjustmentToHome}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        postDischargeQuestions: {
                          ...form.postDischargeQuestions,
                          adjustmentToHome: e.target.value,
                        },
                      });
                      setValidationErrors({ ...validationErrors, adjustmentToHome: false });
                    }}
                    error={validationErrors.adjustmentToHome}
                  />
                </div>
                <div className="row">
                  <div className="question">
                    <p>Are you able to eat/drink?</p>
                  </div>
                  <RadioSelect
                    name="nutrition"
                    value={form.postDischargeQuestions.nutrition}
                    options={YES_NO_NOT_APPLICABLE}
                    validationMessage={validationErrors.nutrition}
                    handleChange={setDischargeQuestionsValue}
                    direction="row"
                  />
                </div>
                <div className="row">
                  <div className="question">
                    <p>Are you able to get around your home safely, including the bathroom?</p>
                  </div>
                  <RadioSelect
                    name="mobility"
                    value={form.postDischargeQuestions.mobility}
                    options={YES_NO_NOT_APPLICABLE}
                    validationMessage={validationErrors.mobility}
                    handleChange={setDischargeQuestionsValue}
                    direction="row"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="question">
                <p>
                  {form.postDischargeQuestions.version === 'v1' && isSubmitted
                    ? 'Following discharge instructions:'
                    : 'Did you understand your D/C instructions, including S/S to watch for and who to contact for worsening symptoms?'}
                </p>
              </div>
              <RadioSelect
                name="dischargeInstructionEducation"
                value={form.postDischargeQuestions.dischargeInstructionEducation}
                options={YES_NO_NOT_APPLICABLE}
                validationMessage={validationErrors.dischargeInstructionEducation}
                handleChange={setDischargeQuestionsValue}
                direction="row"
              />
            </div>
            {form.postDischargeQuestions.version === 'v1' && isSubmitted ? null : (
              <div className="row">
                <div className="question">
                  <p>Have you filled all of your prescriptions?</p>
                </div>
                <RadioSelect
                  name="prescription"
                  value={form.postDischargeQuestions.prescription}
                  options={YES_NO_NOT_APPLICABLE}
                  validationMessage={validationErrors.prescription}
                  handleChange={setDischargeQuestionsValue}
                  direction="row"
                />
              </div>
            )}
            <div className="row">
              <div className="question">
                <p>
                  {form.postDischargeQuestions.version === 'v1' && isSubmitted
                    ? 'Taking medications as prescribed:'
                    : 'Are you taking your medications as recommended?'}
                </p>
              </div>
              <RadioSelect
                name="medicationCompliance"
                value={form.postDischargeQuestions.medicationCompliance}
                options={YES_NO_NOT_APPLICABLE}
                validationMessage={validationErrors.medicationCompliance}
                handleChange={setDischargeQuestionsValue}
                direction="row"
              />
            </div>
            <div className="row directionColumn serviceNeeds">
              <p>
                {form.postDischargeQuestions.version === 'v1' && isSubmitted
                  ? 'Community services needed:'
                  : 'Were you referred any community or home services at discharge?'}
                <span className="required">{validationErrors.serviceNeeds}</span>
              </p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.postDischargeQuestions.serviceNeeds?.none?.length > 0}
                      onChange={handleServiceNeeds}
                      name="none"
                      value="None"
                    />
                  }
                  label="None"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.postDischargeQuestions.serviceNeeds?.homeHealth?.length > 0}
                      onChange={handleServiceNeeds}
                      name="homeHealth"
                      value="Home Health"
                    />
                  }
                  label="Home Health"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.postDischargeQuestions.serviceNeeds?.physicalTherapy?.length > 0}
                      onChange={handleServiceNeeds}
                      name="physicalTherapy"
                      value="Physical Therapy"
                    />
                  }
                  label="Physical Therapy"
                />
                <div>
                  <FormControlLabel
                    control={
                      <div>
                        <Checkbox
                          checked={form.postDischargeQuestions.serviceNeeds?.other.length > 0}
                          name="other"
                          value="Other:"
                        />
                      </div>
                    }
                    label="Other:"
                  />
                  <TextField
                    value={form.postDischargeQuestions.serviceNeeds?.other}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        postDischargeQuestions: {
                          ...form.postDischargeQuestions,
                          serviceNeeds: {
                            ...form.postDischargeQuestions.serviceNeeds,
                            other: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
          <div className="pcp single-line section">
            <h3>Primary Care Provider:</h3>
            <TextField
              value={form.pcp}
              onChange={(e) => {
                setForm({
                  ...form,
                  pcp: e.target.value,
                });
              }}
            />
          </div>
          <div className="patientAppointments section">
            <h3>Appointments:</h3>
            {patientAppointments.length ? (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Provider</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Dept</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Billing</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientAppointments.map((appt) => (
                      <TableRow key={appt.appointmentId}>
                        <TableCell>{appt.date}</TableCell>
                        <TableCell>
                          {appt.startTime}
                          <br />({appt.duration} mins)
                        </TableCell>
                        <TableCell>{appt.provider}</TableCell>
                        <TableCell>{appt.appointmentType}</TableCell>
                        <TableCell>{appt.department}</TableCell>
                        <TableCell className="status">
                          {appointmentStatusIcon(appt.appointmentStatus, appt.appointmentStatusVerbose)}
                        </TableCell>
                        <TableCell>
                          {appt.billedProceduceCodes.map((code) => (
                            <div key={code}>{code}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              <div
                                style={{
                                  whiteSpace: 'pre-line',
                                  fontSize: '14px',
                                }}
                              >
                                {appt.notes}
                              </div>
                            }
                            placement="bottom"
                            arrow
                          >
                            <TextSnippetOutlinedIcon sx={{ color: appt.notes?.length ? 'black' : 'gray' }} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>Patient does not have any appointments in the next 14 days.</p>
            )}
          </div>
          <div className="tcm single-line section">
            <h3>TCM Provider:</h3>
            <FormControl>
              {/* 
                Here, for the value prop, we lookup and use the exact data from memory that we are
                populating the <select /> with. If the value prop doesn't point to the same data
                then lots of warnings get thrown in the console which is super annoying.
              */}
              <Select value={providers.find((p) => p.id === form.patient.tcmProvider?.id)?.id || ''} onChange={setTcm}>
                {providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="followUp single-line section">
            <h3>Scheduled TCM follow-up date:</h3>
            <div
              onClick={() => {
                if (form.patient.tcmProvider) {
                  setShowApptModal(true);
                }
              }}
            >
              <DateInput value={form.patient.appointment ? form.patient.appointment.date : ''} hideTime disabled />
            </div>
            {showApptModal ? (
              <ApptModal
                setShowModal={setShowApptModal}
                form={form}
                setForm={setForm}
                setErrors={props.setErrors}
                providerId={form.patient.tcmProvider.id}
                appointmentId={form.patient.appointment ? form.patient.appointment.id : ''}
                patientId={form.patient.id}
                fetchPatientAppointments={fetchPatientAppointments}
              />
            ) : null}
          </div>
          <div className="review single-line section">
            <h3>Case needs review?</h3>
            <RadioSelect
              name="needReview"
              value={bool2str(form.needReview)}
              options={YES_NO}
              validationMessage={validationErrors.needReview}
              handleChange={setFormValue}
              direction="row"
              convertToBool
            />
          </div>
          <div className="review single-line section">
            <h3>Mark case urgent?</h3>
            <RadioSelect
              name="urgent"
              value={bool2str(form.urgent)}
              options={YES_NO}
              validationMessage={validationErrors.urgent}
              handleChange={setFormValue}
              direction="row"
              convertToBool
            />
          </div>
          <div className="specialist single-line section">
            <h3>Specialist:</h3>
            <TextField
              value={form.specialist}
              onChange={(e) => {
                setForm({
                  ...form,
                  specialist: e.target.value,
                });
              }}
            />
          </div>
          <div className="specialistDate single-line section">
            <h3>Specialist follow-up date:</h3>
            <DateInput
              name="specialistFollowupDate"
              value={form.specialistFollowupDate}
              handleChange={handleFormDateChange}
              clearDate={clearDate}
              hideTime
            />
          </div>
          <div className="notes section">
            <h3>Notes:</h3>
            <TextField
              multiline
              rows={6}
              value={form.notes}
              onChange={(e) => {
                setForm({
                  ...form,
                  notes: e.target.value,
                });
              }}
            />
          </div>
          {isAthenaSubmit(isSubmitted) ? (
            <CaseActionNotes caseId={form.id} staff={providerStaff} caseType={PATIENT_CASE_TYPES.IC} />
          ) : null}
          {isAthenaSubmit(isSubmitted) ? (
            <AuditHistory patientId={patientIdFromUrl} type={PATIENT_AUDIT_HISTORY_TYPES.IC} />
          ) : null}
          {isAthenaSubmit(isSubmitted) ? null : (
            <div className="save">
              <Button variant="contained" onClick={saveChanges} disabled={Boolean(isSubmitted) || loading}>
                Save Changes
              </Button>
              <Button
                variant="contained"
                onClick={submitToAthena}
                disabled={Boolean(isSubmitted) || form.patient.label === 'Not Eligible' || loading}
              >
                Submit to Athena
              </Button>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}
