import './style.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

export default function InvoiceSummary(props) {
  const { summary } = props;

  return (
    <TableContainer className="invoice-summary">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Facility</TableCell>
            <TableCell>99495 Claims Submitted</TableCell>
            <TableCell>99495 Charge</TableCell>
            <TableCell>99496 Claims Submitted</TableCell>
            <TableCell>99496 Charge</TableCell>
            <TableCell>Total TCM Claims</TableCell>
            <TableCell>Kouper TCM Charge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summary.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`detail-${index}`}>
              <TableCell className="facility">{row.facility}</TableCell>
              <TableCell align="center">{row.moderateComplexityClaimCount}</TableCell>
              <TableCell align="center">{row.moderateComplexityInvoiceAmount}</TableCell>
              <TableCell align="center">{row.highComplexityClaimCount}</TableCell>
              <TableCell align="center">{row.highComplexityInvoiceAmount}</TableCell>
              <TableCell align="center">{row.totalCount}</TableCell>
              <TableCell align="center">{row.totalAmount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
