import './style.scss';

import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';

import Visualization from '../Visualization/component';
import BookingsReport from './BookingsReport';
import ICMetricsReport from './ICMetricsReport';
import Reconcile from './Reconcile';
import SummaryReport from './SummaryReport';

export default function Reports() {
  const [selectedTab, setSelectedTab] = useState('summary');

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <Container className="reports">
      <h2>Reports</h2>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#20ce99',
          },
        }}
      >
        <Tab label="Summary" value="summary" />
        <Tab label="Kouper Bookings" value="kouperBookings" />
        <Tab label="IC Metrics" value="icMetrics" />
        <Tab label="Reconcile" value="reconcile" />
        <Tab label="Charts" value="charts" />
      </Tabs>

      {selectedTab === 'summary' ? <SummaryReport /> : null}
      {selectedTab === 'kouperBookings' ? <BookingsReport /> : null}
      {selectedTab === 'icMetrics' ? <ICMetricsReport /> : null}
      {selectedTab === 'reconcile' ? <Reconcile /> : null}
      {selectedTab === 'charts' ? <Visualization /> : null}
    </Container>
  );
}
