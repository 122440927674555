import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

import Loader from '../components/Loader';

export default function ProtectedRoute({ component, ...propsForComponent }) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component {...propsForComponent} />;
}
