import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  patients: [],
  patientProfiles: {},
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    setPatientProfile: (state, action) => {
      state.patientProfiles[action.payload.id] = action.payload;
    },
    addPatientCaseId: (state, action) => {
      state.patientProfiles[action.payload.id] = {
        ...state.patientProfiles[action.payload.id],
        caseId: action.payload.caseId,
      };
    },
    resetSystemState: (state) => {
      state = initialState;
    },
  },
});

export const { setPatients, resetSystemState, setPatientProfile, addPatientCaseId } = patientSlice.actions;

export default patientSlice.reducer;

export const selectPatients = (state) => state.patient.patients;
export const selectPatientProfiles = (state) => state.patient.patientProfiles;
