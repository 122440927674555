export const PATIENT_BILLING_CODES = ['None', '99496', '99495', '99215', '99214', '99213', '99212'];

export const PATIENT_LABEL_OPTIONS = {
  None: 'None',
  'Not Eligible': 'Not Eligible',
  'Not Captured': 'Not Captured',
  'f2f-within-2-days': 'F2F within 2 Days',
};

export const PATIENT_CASE_TYPES = {
  IC: 'ic',
  COMMON: 'common',
};

export const PATIENT_AUDIT_HISTORY_TYPES = {
  IC: 'icform',
  PROFILE: 'profile',
  CASE: 'case',
};
