import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';

import BaymaxApi from '../../BaymaxApi';
import { DATE_RANGES, REPORT_BY_PERIOD_INITIAL_STATE, REPORT_BY_PERIOD_ROWS } from '../../utils/constants/reports';
import DateRangeSelector from '../DateRangeSelector/component';

export default function ICMetricsReport() {
  const { getAccessTokenSilently } = useAuth0();
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [icMetrics, setIcMetrics] = useState({});
  const [dateRanges, setDateRanges] = useState(REPORT_BY_PERIOD_INITIAL_STATE);

  useEffect(() => {
    fetchIcMetrics();

    DATE_RANGES.forEach((range) => updateDateRange(range, dateRanges[range].startDate, dateRanges[range].endDate));
  }, []);

  const fetchIcMetrics = async () => {
    const token = await getAccessTokenSilently();
    const icMetrics = await BaymaxApi.getIcMetrics(token);

    setIcMetrics(icMetrics);
  };

  const fetchIcMetricsByPeriod = async (startDate, endDate) => {
    const token = await getAccessTokenSilently();
    const icMetrics = await BaymaxApi.getIcMetricsByPeriod(startDate, endDate, token);

    return icMetrics;
  };

  const updateDateRange = async (key, startDate, endDate) => {
    const dateRange = dateRanges[key];
    const data = await fetchIcMetricsByPeriod(startDate, endDate);

    dateRange.startDate = startDate;
    dateRange.endDate = endDate;
    dateRange.data = data;

    setDateRanges({
      ...dateRanges,
      [key]: dateRange,
    });
  };

  return (
    <div className="ic-metrics">
      <div className="single">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Engagement Metrics</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>IC Calls Connected</TableCell>
                <TableCell>{icMetrics.connected}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IC Async Documented</TableCell>
                <TableCell>{icMetrics.documented}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>F2F Within 2 Day Capture</TableCell>
                <TableCell>{icMetrics.f2fWithinTwoDays}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total IC Attempts</TableCell>
                <TableCell>{icMetrics.totalAttempts}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bold">TCM Billed</TableCell>
                <TableCell className="bold">{icMetrics.billed}</TableCell>
              </TableRow>
              <TableRow className="row-line">
                <TableCell className="bold">Program Success Rate</TableCell>
                <TableCell className="bold">{icMetrics.programSuccessRate?.toFixed(2)}%</TableCell>
              </TableRow>
              <TableRow className="row-line">
                <TableCell className="bold">Phone Success Rate</TableCell>
                <TableCell className="bold">{icMetrics.phoneSuccessRate?.toFixed(2)}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="comparison">
        <h4>3-Period Comparison</h4>
        <FormGroup className="breakdown-toggle">
          <FormControlLabel
            control={<Switch checked={showBreakdown} onChange={() => setShowBreakdown(!showBreakdown)} />}
            label="Show Assigned/Unassigned"
            labelPlacement="start"
          />
        </FormGroup>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className="first-col" />
                {DATE_RANGES.map((range) => (
                  <TableCell key={range} className="date-picker">
                    <DateRangeSelector
                      id={range}
                      startDate={dateRanges[range].startDate}
                      endDate={dateRanges[range].endDate}
                      updateDateRange={updateDateRange}
                    />
                    {showBreakdown ? (
                      <div className="breakdown">
                        <div>Assigned</div>
                        <div>Unassigned</div>
                      </div>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {REPORT_BY_PERIOD_ROWS.map((row) => (
                <TableRow key={row.id} className={row.rowClasses}>
                  <TableCell className={row.cellClasses}>{row.name}</TableCell>
                  {DATE_RANGES.map((range) => (
                    <TableCell key={`${range}-${row.id}`} className={row.cellClasses} align="center">
                      {showBreakdown ? (
                        <div className="breakdown">
                          <div>
                            {row.isPercentage
                              ? `${dateRanges[range].data[row.id].assigned.toFixed(2)}%`
                              : dateRanges[range].data[row.id].assigned}
                          </div>
                          <div>
                            {row.isPercentage
                              ? `${dateRanges[range].data[row.id].unassigned.toFixed(2)}%`
                              : dateRanges[range].data[row.id].unassigned}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {row.isPercentage ? (
                            <div>{dateRanges[range].data[row.id].total.toFixed(2)}%</div>
                          ) : (
                            <div>{dateRanges[range].data[row.id].total}</div>
                          )}
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
